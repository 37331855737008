import { useState } from 'react';

import { Button } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';

import { ExportGridOpts, useExportGrid } from './useExportGrid';

export const ExportGridButton = <T,>({
  getDetailGridState,
  ...props
}: Omit<ExportGridOpts<T>, 'columnState' | 'filterModel'> & {
  getDetailGridState: () => Pick<ExportGridOpts<T>, 'columnState' | 'filterModel'>;
}) => {
  const { exportGrid, exportRunning } = useExportGrid();
  const [loading, setLoading] = useState(false);
  return (
    <Button
      onClick={() => {
        setLoading(true);
        exportGrid({
          ...props,
          ...getDetailGridState(),
        }).finally(() => setLoading(false));
      }}
      disabled={exportRunning && !loading}
      loading={loading}
      compact
      size="xs"
      variant="outline"
      leftIcon={<EvolveIcon icon="Export" color="inherit" size="xs" />}
    >
      Export
    </Button>
  );
};
