import { useMemo, useState } from 'react';

import { Button, Divider, Flex, Select, SelectItem, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import type { SelectedProject } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { strongFromEntries } from 'helpers/strongEntries';
import type { DepartmentId, DepartmentType } from 'hooks/projectsAndFacilities/types';
import { useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { ProjectUnitsType, ProjectUpdate, useProjects } from 'hooks/projectsAndFacilities/useProjects';

import type { ProjDepartments } from './types';

type Props = {
  selectedProject: SelectedProject;
};

export const ProjectPropertiesForm = ({ selectedProject }: Props) => {
  const { projects, updateProject, loading, projTypes, depTypes } = useProjects();
  const projectProps = projects.find((f) => f.projectId === selectedProject.id);
  const projectUnits: ProjectUnitsType[] = ['Metric', 'Imperial'];
  const { facilities } = useFacilities();
  const departments: (SelectItem & { type: DepartmentType })[] = facilities
    .flatMap((f) => f.departments ?? [])
    .map((dep) => ({ value: dep.departmentId, label: dep.departmentName, type: dep.departmentType }));

  const [saving, setSaving] = useState(false);

  const depTypeMap = useMemo(
    () => strongFromEntries(depTypes?.map((dt) => [dt.departmentTypeName, dt.departmentTypeId]) ?? []),
    [depTypes],
  );

  const form = useForm<ProjDepartments & Omit<ProjectUpdate, 'departmentIds'>>({
    initialValues: {
      projectName: projectProps?.projectName,
      projectIdentifier: projectProps?.projectIdentifier,
      projectTypeId: projectProps?.projectType?.projectTypeId,
      shopDep: projectProps?.departments?.find((dep) => dep.departmentType.departmentTypeName === 'Shop')?.departmentId,
      procurementDep: projectProps?.departments?.find((dep) => dep.departmentType.departmentTypeName === 'Procurement')
        ?.departmentId,
      designDep: projectProps?.departments?.find((dep) => dep.departmentType.departmentTypeName === 'Design')
        ?.departmentId,
      units: projectProps?.units,
      addressLine1: projectProps?.address.addressLine1,
      addressLine2: projectProps?.address.addressLine2,
      city: projectProps?.address.city,
      state: projectProps?.address.state,
      zip: projectProps?.address.zip,
      country: projectProps?.address.country,
    },
    validate: {
      projectName: (n) => !n,
      projectIdentifier: (i) => !i,
    },
  });

  const onSubmit = (values: typeof form.values) => {
    setSaving(true);
    const changedValues = Object.fromEntries(Object.entries(values).filter(([key]) => form.isDirty(key)));
    const { procurementDep, designDep, shopDep, ...rest } = changedValues;
    const departmentIds = (() => {
      const depIdList: DepartmentId[] = [];
      if (procurementDep) depIdList.push(procurementDep as DepartmentId);
      if (designDep) depIdList.push(designDep as DepartmentId);
      if (shopDep) depIdList.push(shopDep as DepartmentId);
      return depIdList;
    })();
    updateProject(selectedProject.id, {
      ...rest,
      ...(departmentIds.length > 0 ? { departmentIds } : {}),
    })
      .then((res) => {
        const { departments: depsUpdated, ...restUpdated } = res;
        const newValues = {
          ...restUpdated,
          shopDep: depsUpdated?.find((dep) => dep?.departmentTypeId === depTypeMap.Shop)?.departmentId,
          procurementDep: depsUpdated?.find((dep) => dep?.departmentTypeId === depTypeMap.Procurement)?.departmentId,
          designDep: depsUpdated?.find((dep) => dep?.departmentTypeId === depTypeMap.Design)?.departmentId,
        };
        form.setValues(newValues);
        form.resetDirty(newValues);
      })
      .finally(() => setSaving(false));
  };

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)} style={{ height: '100%', overflowY: 'auto' }}>
        <Flex direction="column">
          <Flex gap="xl" style={{ height: '100%', width: '100%' }}>
            <Flex direction="column" gap="xs" style={{ maxWidth: '600px', width: '50%' }}>
              <TextInput
                label="Project Title"
                maxLength={64}
                required
                {...form.getInputProps('projectName')}
                disabled={saving}
              />
              <TextInput
                label="Project Id"
                maxLength={64}
                required
                {...form.getInputProps('projectIdentifier')}
                disabled={saving}
              />
              <Select
                data={projTypes?.map((type) => ({ value: type.projectTypeId, label: type.projectTypeName })) ?? []}
                label="Project Type"
                clearable
                {...form.getInputProps('projectTypeId')}
                disabled={saving}
              />
              <Select
                data={departments.filter((dep) => dep.type.departmentTypeName === 'Shop')}
                label="Shop Department"
                clearable
                {...form.getInputProps('shopDep')}
                disabled={saving}
              />
              <Select data={projectUnits} label="Units" clearable {...form.getInputProps('units')} disabled={saving} />
            </Flex>

            <Divider orientation="vertical" mb={0} />

            <Flex direction="column" justify="space-between" style={{ maxWidth: '400px', width: '50%' }}>
              <Flex direction="column" gap="xs" style={{ width: '100%' }}>
                <TextInput
                  label="Address Line 1"
                  maxLength={64}
                  {...form.getInputProps('addressLine1')}
                  disabled={saving}
                />
                <TextInput
                  label="Address Line 2"
                  maxLength={64}
                  {...form.getInputProps('addressLine2')}
                  disabled={saving}
                />
                <TextInput label="City" maxLength={64} {...form.getInputProps('city')} disabled={saving} />
                <Flex direction="row" gap="md" style={{ width: '100%' }}>
                  <TextInput
                    label="State"
                    maxLength={64}
                    style={{ width: '100%' }}
                    {...form.getInputProps('state')}
                    disabled={saving}
                  />
                  <TextInput
                    label="Zip/Postal Code"
                    maxLength={64}
                    style={{ width: '100%' }}
                    {...form.getInputProps('zip')}
                    disabled={saving}
                  />
                </Flex>
                <TextInput label="Country" maxLength={64} {...form.getInputProps('country')} disabled={saving} />
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="row" gap="sm" mt="xl">
            <Button
              variant="subtle"
              color="gray"
              c="dark"
              onClick={() => form.reset()}
              disabled={saving || !form.isDirty()}
            >
              Discard changes
            </Button>
            <Button loading={saving} disabled={!form.isValid() || !form.isDirty() || loading} type="submit">
              Save changes
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};
