import type { FormEvent, ReactNode } from 'react';

import { Alert, Box, Flex, Title } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';

import { LoginErrorAlert, LoginErrorCode } from './LoginErrorAlert';

type Props<T> = {
  title: string;
  children: ReactNode;
  successMessage?: string;
  errorCode?: LoginErrorCode;
  onCloseSuccess?: () => void;
  onCloseError?: () => void;
} & (
  | {
      form: UseFormReturnType<T>;
      onSubmit: (values: T, event?: FormEvent<HTMLFormElement>) => void;
    }
  | {
      form?: never;
      onSubmit: () => void;
    }
);

export const AuthFormWrapper = <T,>({
  title,
  form,
  onSubmit,
  children,
  successMessage,
  errorCode,
  onCloseSuccess,
  onCloseError,
}: Props<T>) => (
  <Box style={{ flex: '1 0 0', maxWidth: 400 }}>
    <Box style={{ minHeight: 50 }}>
      {successMessage && (
        <Alert color="green" variant="light" withCloseButton onClose={onCloseSuccess}>
          {successMessage}
        </Alert>
      )}
      <LoginErrorAlert errorCode={errorCode} onClose={onCloseError} />
    </Box>
    <form onSubmit={form ? form.onSubmit(onSubmit) : onSubmit}>
      <Flex direction="column" gap="md" mt="xl" mb={140}>
        <Title fw={400}>{title}</Title>
        {children}
      </Flex>
    </form>
  </Box>
);
