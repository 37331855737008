import { useState, useEffect } from 'react';

import { Button } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { NewPasswordInput } from 'app/Layout/HeaderBar/ProfileDropdown/ResetPasswordModal/NewPasswordInput';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';
import useQuery from 'hooks/useQuery';

import { useFetchProviderStatus } from './auth/restCalls';
import { AuthFormWrapper } from './components/AuthFormWrapper';
import type { LoginErrorCode } from './components/LoginErrorAlert';

export const ResetPassword = () => {
  const { fetchProviderStatus } = useFetchProviderStatus();
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const { apiCall } = useWrappedPost<unknown, { confirmationCode: string; email: string; newPassword: string }>(
    'admin/authentication/confirmForgotPassword',
  );
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<LoginErrorCode>();
  const navigate = useNavigate();
  const queryParams = useQuery();

  const form = useForm<{ newPassword: string }>({
    initialValues: {
      newPassword: '',
    },
  });

  useEffect(() => {
    if (changeSuccess) {
      navigate('/auth/login', { state: { resetPassword: changeSuccess } });
    }
  }, [setChangeSuccess, navigate, changeSuccess]);

  const resetPasswordHandler = async ({ newPassword }: typeof form.values) => {
    setLoading(true);
    const email = queryParams.get('email');
    const confirmationCode = queryParams.get('code');

    if (email && confirmationCode) {
      try {
        const emailAllowChar = email.split(' ').join('+');
        const status = await fetchProviderStatus(emailAllowChar);
        if ('userName' in status) {
          await apiCall({ confirmationCode, email, newPassword });
        } else {
          setErrorCode('UserNotFoundException');
        }
      } catch (error: any) {
        setErrorCode(error.code);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorCode('UnknownException');
    }
  };

  return (
    <AuthFormWrapper
      title="Create new password"
      onSubmit={resetPasswordHandler}
      form={form}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
    >
      <NewPasswordInput
        {...form.getInputProps('newPassword')}
        loading={loading}
        setPasswordIsValid={setPasswordIsValid}
      />
      <Button type="submit" fullWidth color="violet.9" loading={loading} disabled={!passwordIsValid}>
        Submit
      </Button>
    </AuthFormWrapper>
  );
};
