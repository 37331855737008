import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';

import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility, type SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import useSetupModule from 'hooks/useSetupModule';
import { getBillOfProcessColDef } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/columnDefs';
import type { Task, WorkRequestItem } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { useUnitOfMeasure } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import { bomItemColDef } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/columnDefs';
import type { BOMItem, WorkRequest } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';

import { useCanEditWorkRequest } from '../../WorkOrdersPage/common';
import { getWorkOrderRequestItemColumnDef } from './columnDefs';
import { DeleteBOPModal } from './DeleteBOPModal';
import type { DetailTab } from './SecondaryPane/WorkRequestOrderDetailsPane';
import { WorkRequestOrderItemsContext } from './WorkRequestOrderItemsContext';

/**
 * The Props used by any Pane of the WorkRequest Details page
 * via useWorkRequestItems() or useWorkRequestOrderItems()
 */
export type WorkRequestPageProps = {
  selectedItem: SelectedItem;
  selectedWorkRequestItems: WorkRequestItem[];
  setSelectedWorkRequestItems: (items: WorkRequestItem[]) => void;
  workRequest: WorkRequest;
  workRequestUpdated: (updatedWR: WorkRequest) => void;
  serverSideGridProps: ReturnType<typeof useWorkRequestItems>;
};

const useWorkRequestItems = (workRequest: WorkRequest) => {
  const { fetchPage: fetchPageWorkRequestItem, setDefaultOpts: setDefaultOptsWorkRequestItem } =
    useWrappedPaginatedGet<WorkRequestItem>('shop/workRequestItem', {
      lazy: true,
    });
  const { fetchPage: fetchPageBOM } = useWrappedPaginatedGet<BOMItem>('shop/workRequestItem/:id/bom', { lazy: true });
  const { fetchPage: fetchPageBOP } = useWrappedPaginatedGet<Task>('shop/task', {
    lazy: true,
    defaultConfig: {
      params: {
        orderBy: 'createdOn:asc',
      },
    },
  });

  useEffect(() => {
    setDefaultOptsWorkRequestItem({
      lazy: true,
      defaultConfig: {
        params: {
          workRequestId: workRequest.workRequestId,
          orderBy: 'workRequestItemName:asc',
        },
      },
    });
  }, [setDefaultOptsWorkRequestItem, workRequest.workRequestId]);
  const isInProgress = workRequest.workRequestStatusName === 'In Progress';
  const { editTasks: canEditTasks, editItems: canEditItems } = useCanEditWorkRequest(workRequest);
  const colDef = useMemo(
    () =>
      getWorkOrderRequestItemColumnDef<WorkRequestItem>({
        nameKey: 'workRequestItemName',
        isInProgress,
        canEditItems,
        canEditTasks,
        isCompleted: workRequest.workRequestStatusName === 'Completed',
      }),
    [isInProgress, canEditItems, canEditTasks, workRequest.workRequestStatusName],
  );
  const bopColDef = useMemo(() => getBillOfProcessColDef(), []);
  return useServerSideGrid({
    tableName: 'word-request-items',
    colDef,
    fetchPage: fetchPageWorkRequestItem,
    saveFilters: false,
    // Using billOfProcessId instead of workRequestItemId
    // so that we can refresh the detail row when we update the bill of process
    rowId: 'billOfProcessId',
    detailTables: [
      {
        title: 'Bill of Materials',
        icon: 'BillOfMaterials',
        colDef: bomItemColDef,
        fetchPage: fetchPageBOM,
        configMapper: (parentRow) => ({
          url: `shop/workRequestItem/${parentRow.workRequestItemId}/bom`,
        }),
      },
      {
        title: 'Bill of Process',
        icon: 'WorkCellTask',
        colDef: bopColDef,
        fetchPage: fetchPageBOP,
        configMapper: (parentRow) => ({
          params: {
            workRequestItemId: parentRow.workRequestItemId,
          },
        }),
        rightSide: ({ billOfProcessId, workRequestItemTypeId }, refresh) => (
          <DeleteBOPModal billOfProcessId={billOfProcessId} itemTypeId={workRequestItemTypeId} refresh={refresh} />
        ),
      },
    ],
  });
};

/**
 * The `useWorkRequestItems` call is used here,
 * so that the AG Grid helpers (like `refreshDetailGrid`)
 * can be called by both panes.
 * This allows the right (editing) pane to refresh the left (table) pane
 * whenever data is updated.
 */
const WorkRequestItemsWrapper = ({
  children,
  ...props
}: { children: ReactNode } & Omit<
  WorkRequestPageProps,
  'serverSideGridProps' | 'selectedWorkRequestItems' | 'setSelectedWorkRequestItems'
>) => {
  const serverSideGridProps = useWorkRequestItems(props.workRequest);
  const [selectedWorkRequestItems, setSelectedWorkRequestItems] = useState<WorkRequestItem[]>([]);
  const [selectedTab, setSelectedTab] = useState<DetailTab>('detail');
  useEffect(() => {
    if (serverSideGridProps.totalRowCount === 0) {
      setSelectedTab('catalog');
    }
  }, [serverSideGridProps.totalRowCount]);
  return (
    <WorkRequestOrderItemsContext.Provider
      value={useMemo(
        () => ({
          serverSideGridProps,
          selectedWorkRequestItems,
          setSelectedWorkRequestItems,
          selectedTab,
          setSelectedTab,
          ...props,
        }),
        [props, selectedTab, selectedWorkRequestItems, serverSideGridProps],
      )}
    >
      {children}
    </WorkRequestOrderItemsContext.Provider>
  );
};

/**
 * Used to verify the selected `WorkRequest` both exists and is loaded into memory.
 */
export const WorkRequestSelectedWrapper = ({
  children,
  selectedItem,
}: {
  children: ReactNode;
  selectedItem: SelectedItem;
}) => {
  const { projects, loading: loadingProjects } = useProjects();
  const { facilities, loading: loadingFacilities } = useFacilities();
  const { selectProject, selectFacility } = useSelectedProjectFacility();
  const navigate = useEvolveNavigate('', true);
  const { currentModule } = useSetupModule();
  const module = (currentModule() ?? 'shop').toLocaleLowerCase();

  const { id: workRequestId } = useParams();
  const { apiCall: getWorkRequest, loading } = useWrappedGet<WorkRequest>(`shop/workRequest/${workRequestId}`, {
    lazy: true,
  });
  const { uoms } = useUnitOfMeasure();
  const { selectedWorkRequest, setSelectedWorkRequest } = useWorkRequests();
  const workRequestMatches = useMemo(
    () => selectedWorkRequest?.workRequestId === workRequestId,
    [selectedWorkRequest?.workRequestId, workRequestId],
  );
  useEffect(() => {
    if (loadingFacilities || loadingProjects) return;
    if (!workRequestMatches) {
      getWorkRequest()
        .then((wr) => {
          if (selectedItem.type === 'FACILITY' && selectedItem.id !== wr.facilityId) {
            const facility = facilities.find((f) => f.facilityId === wr.facilityId);
            if (isNotNil(facility)) {
              notifications.show({
                title: 'Work Request in a different facility',
                message: `Swapped to facility ${facility.facilityName}`,
              });
              selectFacility(facility);
            }
          } else if (selectedItem.type === 'PROJECT' && selectedItem.id !== wr.projectId) {
            const project = projects.find((f) => f.projectId === wr.projectId);
            if (isNotNil(project)) {
              notifications.show({
                title: 'Work Request in a different project',
                message: `Swapped to ${project.projectName}`,
              });
              selectProject(project);
            }
          }
          setSelectedWorkRequest(wr);
        })
        .catch(() => {
          // TODO: route to previous page
          // show error, too? maybe only on non-404s
          navigate(`/${module}/work-requests`);
        });
    }
  }, [
    facilities,
    getWorkRequest,
    loadingFacilities,
    loadingProjects,
    module,
    navigate,
    projects,
    selectFacility,
    selectProject,
    selectedItem.id,
    selectedItem.type,
    setSelectedWorkRequest,
    workRequestMatches,
  ]);

  if (isNil(selectedWorkRequest) || isNil(uoms) || loading || !workRequestMatches) {
    return <Loader m="lg" />;
  }
  if (selectedItem.id !== selectedWorkRequest.facilityId && selectedItem.id !== selectedWorkRequest.projectId) {
    setTimeout(() => navigate(`/${module}/work-requests`));
    return null;
  }
  return (
    <WorkRequestItemsWrapper
      selectedItem={selectedItem}
      workRequest={selectedWorkRequest}
      workRequestUpdated={setSelectedWorkRequest}
    >
      {children}
    </WorkRequestItemsWrapper>
  );
};
