import { useEffect, useState } from 'react';

import { Button, Card, Divider, Flex, List, Loader, Modal, Text, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { isNil } from 'helpers/isNotNil';
import { strongEntries, strongKeys } from 'helpers/strongEntries';
import type { TaskId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import type { WorkOrder } from '../../../WorkOrdersPage/types';
import { useWorkOrders } from '../../../WorkOrdersPage/useWorkOrders';
import { CancelWorkOrderTasks } from './CancelWorkOrderTasks';
import { useInProgressTasks } from './useInProgressTasks';

type Props = {
  workOrder: WorkOrder;
  onUpdate: (workOrder: WorkOrder) => void;
  disabled: boolean;
};

export const CancelWorkOrderModal = ({ workOrder, onUpdate, disabled }: Props) => {
  const { user } = useUser();
  const [modalOpened, setModalOpened] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { workOrderStatuses, updateWorkOrderStatus } = useWorkOrders();

  const [tasksToUpdate, setTasksToUpdate] = useState<Record<TaskId, boolean>>({});
  const [readyToSubmit, setReadyToSubmit] = useState(false);

  const setTaskDecision = (taskId: TaskId, isSaveData: boolean) => {
    setTasksToUpdate((tasks) => ({
      ...tasks,
      [taskId]: isSaveData,
    }));
  };

  const { fetchInProgressTasks, updateInProgressTasks, loading, tasks } = useInProgressTasks(workOrder);

  useEffect(() => {
    const taskIds = strongKeys(tasksToUpdate);
    setReadyToSubmit(
      taskIds.length === tasks.length && emailInput.toLocaleLowerCase() === user?.userEmail.toLocaleLowerCase(),
    );
  }, [emailInput, tasks.length, tasksToUpdate, user?.userEmail]);

  if (isNil(workOrderStatuses)) return null;

  const hasInProgressTasks = tasks.length > 0;

  const onSubmit = () => {
    const releaseStatus = workOrderStatuses.find((s) => s.workOrderStatusTypeName === 'Blocked')?.workOrderStatusTypeId;
    if (isNil(releaseStatus)) {
      notifications.show({
        title: 'Something went wrong',
        message: `Could not find 'Blocked' status.`,
        color: 'red',
      });
      return;
    }
    setUpdating(true);
    const decisions = strongEntries(tasksToUpdate).map(([taskId, isSaveData]) => ({
      taskId,
      isSaveData,
    }));
    updateInProgressTasks(decisions)
      .then(() => updateWorkOrderStatus(workOrder.workOrderId, releaseStatus))
      .then((updatedWorkOrder) => {
        notifications.show({
          color: 'green',
          title: 'Successfully updated',
          message: `Canceled work order ${workOrder.workOrderName}`,
        });
        return updatedWorkOrder;
      })
      .then(onUpdate)
      .then(() => setModalOpened(false))
      .finally(() => setUpdating(false));
  };

  return (
    <>
      <Button
        onClick={() => {
          setTasksToUpdate({});
          setEmailInput('');
          setEmailError(false);
          fetchInProgressTasks();
          setModalOpened(true);
        }}
        loading={updating}
        variant="outline"
        disabled={disabled || modalOpened}
        size="sm"
      >
        Cancel WO
      </Button>
      <Modal
        title={`Confirm cancelation of "${workOrder.workOrderStatusTypeName}" work order`}
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        closeOnClickOutside={false}
        closeOnEscape={false}
        centered
        size="lg"
        withCloseButton={!updating}
      >
        <Flex direction="column" gap="md">
          <Divider />
          <Text fz="sm">
            Canceling this work order will allow you to make changes and release it again when ready.{' '}
            {hasInProgressTasks && (
              <>
                You can also decide how to handle the <b>in progress tasks</b> listed below.
              </>
            )}
          </Text>
          <Card style={{ backgroundColor: '#eee' }}>
            <Card.Section p="sm">
              <Text fz="sm" mb={3}>
                Canceling will:
              </Text>
              <List fz="sm">
                <List.Item>
                  Change the work order status to <b>Blocked</b>
                </List.Item>
                <List.Item>Pause in progress tasks</List.Item>
                <List.Item>Remove all tasks from their work cells</List.Item>
              </List>
            </Card.Section>
          </Card>
          {loading && (
            <Flex justify="center">
              <Loader />
            </Flex>
          )}
          {hasInProgressTasks && (
            <CancelWorkOrderTasks
              workOrder={workOrder}
              tasks={tasks}
              tasksToUpdate={tasksToUpdate}
              setTaskDecision={setTaskDecision}
              disabled={updating}
            />
          )}
          <TextInput
            label="Please type your email to confirm cancel:"
            type="email"
            onChange={(e) => {
              setEmailError(false);
              setEmailInput(e.target.value);
            }}
            value={emailInput}
            disabled={updating}
            onBlur={() => setEmailError(emailInput.toLocaleLowerCase() !== user?.userEmail.toLocaleLowerCase())}
            error={emailError ? 'Email does not match.' : undefined}
          />
          <Divider />
          <Flex gap="md" justify="flex-end">
            <Button color="gray" c="dark" variant="subtle" disabled={updating} onClick={() => setModalOpened(false)}>
              Go back
            </Button>
            <Button color="red" onClick={onSubmit} loading={updating} disabled={!readyToSubmit || loading}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};
