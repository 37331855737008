import React, { useCallback, useMemo, useRef, useState } from 'react';

import type { AgGridReact } from 'ag-grid-react';

import { isNil, isNotNil } from 'helpers/isNotNil';
import { getLocalStorage, setLocalStorage } from 'hooks/useLocalStorage';

export const useSaveColumnState = <TData,>(
  tableName: string,
  gridRef: React.RefObject<AgGridReact<TData>>,
  saveFilters = true,
) => {
  const tableIdentifier = useMemo(() => `GRID_${tableName}_v4` as const, [tableName]);
  const [sortIsSet, setSortIsSet] = useState(false);
  const [filterIsSet, setFilterIsSet] = useState(false);
  // We shouldn't save column state until we have finished loading
  const columnStateRef = useRef(false);

  const saveColumnState = useCallback(() => {
    const api = gridRef.current?.api;
    if (isNil(api)) return;
    const columnState = api.getColumnState().filter((c) => !c.colId.startsWith('doNotSave'));
    const filterModel = api.getFilterModel();
    setSortIsSet(columnState.some((c) => isNotNil(c.sort)));
    setFilterIsSet(Object.keys(filterModel).length > 0 || columnState.some((s) => isNotNil(s.sort)));
    if (columnStateRef.current) {
      setLocalStorage(tableIdentifier, JSON.stringify({ columnState, filterModel }));
    }
  }, [gridRef, tableIdentifier]);

  const loadColumnState = useCallback(() => {
    try {
      const tableStateRaw = getLocalStorage(tableIdentifier) as string;
      if (isNotNil(tableStateRaw) && tableStateRaw.length > 0) {
        const { columnState, filterModel } = JSON.parse(tableStateRaw);
        setTimeout(() => {
          try {
            gridRef.current?.api.applyColumnState({ state: columnState, applyOrder: true });
            if (saveFilters) {
              gridRef.current?.api.setFilterModel(filterModel);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.warn('Invalid column state in local storage for table', tableIdentifier);
          } finally {
            columnStateRef.current = true;
          }
        });
      } else {
        columnStateRef.current = true;
      }
    } catch (e) {
      columnStateRef.current = true;
      // eslint-disable-next-line no-console
      console.warn('Could not parse column state in local storage for table', tableIdentifier);
    }
  }, [tableIdentifier, gridRef, saveFilters]);

  return useMemo(
    () => ({
      saveColumnState,
      loadColumnState,
      sortIsSet,
      filterIsSet,
    }),
    [filterIsSet, loadColumnState, saveColumnState, sortIsSet],
  );
};
