import { useEffect, useMemo, useState } from 'react';

import { Text, Button, Divider, Flex, Modal, TextInput, Textarea, Select, Loader } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import type { SelectedProject } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { ConfirmationModal } from 'components/Mantine/ConfirmationModal';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { Facility } from 'hooks/projectsAndFacilities/useFacilities';
import { Project, useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { DEPARTMENT_TYPE } from 'types/DepartmentTypes';

import { CreateWorkRequestBody, useWorkRequests } from './useWorkRequests';

type Props = {
  project: Project | undefined;
  facilities: Facility[];
  selectedProject: SelectedProject;
  onClose: () => void;
};

const NewWorkRequestForm = ({ project, selectedProject, facilities, onClose }: Props) => {
  const { createWorkRequest, mutationRunning } = useWorkRequests();
  const [saving, setSaving] = useState(false);
  const navigate = useNavigate();

  const defaultFacilityId = useMemo(() => {
    const foundFacilityId = project?.departments?.find((d) => isNotNil(d.facility))?.facility?.facilityId;
    return foundFacilityId ?? facilities.length === 1 ? facilities[0].facilityId : null;
  }, [facilities, project?.departments]);

  const form = useForm<CreateWorkRequestBody>({
    initialValues: {
      facilityId: defaultFacilityId,
      projectId: selectedProject.id,
      workRequestName: '',
      workRequestDescription: '',
    } as CreateWorkRequestBody,
    validate: {
      facilityId: (v) => !v,
      workRequestName: (v) => !v,
    },
  });

  const onSubmit = (values: typeof form.values) => {
    setSaving(true);
    createWorkRequest({
      ...values,
      projectId: selectedProject.id,
    })
      .then((newWorkRequest) => {
        onClose();
        navigate(newWorkRequest.workRequestId);
      })
      .finally(() => setSaving(false));
  };

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Flex direction="column" gap="xs">
          <Flex my="sm">
            <Flex direction="column" style={{ width: '50%' }}>
              <Text fz="xs" c="dimmed">
                Project Name
              </Text>
              <Text fz="sm">{selectedProject.label}</Text>
            </Flex>
            <Flex direction="column" style={{ width: '50%' }}>
              <Text fz="xs" c="dimmed">
                Project Id
              </Text>
              <Text fz="sm">{project?.projectIdentifier}</Text>
            </Flex>
          </Flex>
          <Flex gap="xs">
            <Select
              style={{ width: '100%' }}
              label="Facility"
              withAsterisk
              searchable
              disabled={saving}
              data={facilities.map((f) => ({
                value: f.facilityId,
                label: f.facilityName,
              }))}
              {...form.getInputProps('facilityId')}
            />
            <DateInput
              style={{ width: '100%' }}
              label="Need By Date"
              minDate={new Date()}
              icon={<EvolveIcon color="inherit" icon="CalendarBoard" />}
              clearable
              disabled={saving}
              {...form.getInputProps('needBy')}
            />
          </Flex>
          <TextInput
            label="Work Request Name"
            maxLength={128}
            withAsterisk
            disabled={saving}
            {...form.getInputProps('workRequestName')}
          />
          <Textarea
            label="Comments"
            maxLength={1000}
            minRows={4}
            disabled={saving}
            {...form.getInputProps('workRequestDescription')}
          />
          <Divider my="xs" />
          <Flex gap="sm" justify="flex-end">
            <Button variant="subtle" color="gray" c="dark" onClick={onClose} disabled={mutationRunning || saving}>
              Cancel
            </Button>
            <Button type="submit" disabled={!form.isValid()} loading={saving}>
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </>
  );
};

export const NewWorkRequestModal = ({ selectedProject }: Pick<Props, 'selectedProject'>) => {
  const [modalOpen, setModalOpen] = useState<'new-work-request' | 'update-project' | null>(null);
  // Standard `useFacilities` call returns only facilities we have assigned to us
  // but we need to be able to assign to any facility in our company that has a shop
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const { fetchPage } = useWrappedPaginatedGet<Facility>('admin/facility', {
    lazy: true,
    defaultConfig: {
      params: {
        companyId: selectedProject.companyId,
        showAvailableFacilities: true,
        departmentTypeIds: DEPARTMENT_TYPE.SHOP,
      },
    },
  });
  const [loadingFacilities, setLoadingFacilities] = useState(true);
  useEffect(() => {
    if (modalOpen === 'new-work-request') {
      setLoadingFacilities(true);
      getAllDataFromFetcher(fetchPage)
        .then(setFacilities)
        .finally(() => setLoadingFacilities(false));
    }
  }, [fetchPage, modalOpen]);

  const { updateProject, projects, mutationRunning: updatingProject } = useProjects();
  const project = useMemo(() => projects.find((p) => p.projectId === selectedProject.id), [projects, selectedProject]);
  const [projectIdentifier, setProjectIdentifier] = useState(project?.projectIdentifier);
  useEffect(() => {
    setProjectIdentifier(project?.projectIdentifier);
  }, [project?.projectIdentifier]);

  return (
    <>
      <Button
        onClick={() => {
          if (!projectIdentifier) {
            setModalOpen('update-project');
          } else {
            setModalOpen('new-work-request');
          }
        }}
        leftIcon={<EvolveIcon icon="Add" color="inherit" size="sm" />}
      >
        New work request
      </Button>
      <Modal
        title="Create new work request"
        opened={modalOpen === 'new-work-request'}
        onClose={() => setModalOpen(null)}
        closeOnClickOutside={false}
        centered
        withinPortal
      >
        {loadingFacilities ? (
          <Flex justify="center">
            <Loader m="xs" variant="bars" />
          </Flex>
        ) : (
          <NewWorkRequestForm
            facilities={facilities}
            onClose={() => setModalOpen(null)}
            project={project}
            selectedProject={selectedProject}
          />
        )}
      </Modal>
      <ConfirmationModal
        opened={modalOpen === 'update-project'}
        onClose={() => {
          setModalOpen(null);
          setProjectIdentifier(project?.projectIdentifier);
        }}
        onConfirm={() =>
          updateProject(selectedProject.id, { projectIdentifier }).then(() => setModalOpen('new-work-request'))
        }
        title="Set Project Id"
        confirmationText="Submit"
        loading={updatingProject || modalOpen === 'new-work-request'}
        disabled={!projectIdentifier}
      >
        <Text mb="sm">
          Before you can create a work request, you first need to set a Project Id for project{' '}
          <b>{selectedProject.label}</b>.
        </Text>
        <TextInput
          placeholder="Project Id"
          value={projectIdentifier}
          onChange={(e) => setProjectIdentifier(e.currentTarget.value)}
          disabled={updatingProject}
          withAsterisk
          autoFocus
        />
      </ConfirmationModal>
    </>
  );
};
