import React, { ReactNode } from 'react';

import { Button, DefaultMantineColor, Divider, Flex, Modal, Text } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';

type Props = React.ComponentProps<typeof Modal> & {
  title?: string;
  confirmationText?: string;
  cancellationText?: string;
  loading?: boolean;
  buttonColor?: DefaultMantineColor;
  disabled?: boolean;
  onConfirm: () => void;
} & (
    | { description: string; children?: never }
    | {
        description?: never;
        children: ReactNode;
      }
  );

export const ConfirmationModal = ({
  title = 'Confirm',
  confirmationText = 'Confirm',
  cancellationText = 'Cancel',
  description,
  children,
  onConfirm,
  onClose,
  buttonColor,
  loading,
  opened,
  disabled,
  ...props
}: Props) => (
  <Modal opened={opened} centered closeOnClickOutside={false} title={title} onClose={onClose} {...props}>
    <div style={{ overflowX: 'hidden' }}>
      {isNotNil(description) && <Text>{description}</Text>}
      {children}
    </div>
    <Divider my="xs" />
    <Flex justify="flex-end" gap="xs">
      <Button onClick={onClose} variant="subtle" color="gray" c="dark" disabled={loading || !opened}>
        {cancellationText}
      </Button>
      <Button color={buttonColor} loading={loading} disabled={!opened || disabled} onClick={onConfirm}>
        {confirmationText}
      </Button>
    </Flex>
  </Modal>
);
