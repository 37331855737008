import { useEffect, useState } from 'react';

import { MultiSelect, MultiSelectProps } from '@mantine/core';

import { isNotNil } from 'helpers/isNotNil';

import { SearchableSelectorProps } from './searchableTypes';
import { useSearchableData } from './useSearchableData';

type Props<T, TKey extends keyof T> = SearchableSelectorProps<T, TKey> &
  Omit<MultiSelectProps, 'value' | 'data' | 'onChange'> & {
    disableSearch?: boolean;
  };

/**
 * Used for MutliSelects whose "search" functinality
 * involves fetching data from the backend.
 */
export const SearchableMultiSelect = <T, TKey extends keyof T>({
  paginatedGet,
  getItemLabel,
  idKey,
  value,
  onChange,
  excludeIds,
  setIsDirty,
  fetchPageOpts,
  searchKey,
  searchValue,
  useLike,
  preselectedOptions = [],
  disableSearch = false,
  placeholder,
  ...multiSelectProps
}: Props<T, TKey>) => {
  const { options, setIsLazy, loading, setSearchPhrase } = useSearchableData({
    paginatedGet,
    fetchPageOpts,
    searchKey,
    useLike,
    lazy: true,
  });
  const [selectedOptions, setSelectedOptions] = useState<T[]>(preselectedOptions);

  useEffect(() => {
    if (isNotNil(searchValue)) {
      setSearchPhrase(searchValue);
    }
  }, [searchValue, setSearchPhrase]);

  useEffect(() => setSelectedOptions((o) => value ?? o), [value]);

  return (
    <MultiSelect
      placeholder={placeholder || 'Type to search...'}
      nothingFound={disableSearch ? undefined : 'No results found.'}
      searchable
      searchValue={searchValue}
      onSearchChange={setSearchPhrase}
      clearSearchOnChange={false}
      clearSearchOnBlur
      onDropdownOpen={() => setIsLazy(disableSearch)}
      onDropdownClose={() => setIsLazy(true)}
      onChange={(v) => {
        const ids = v as T[TKey][];
        const stillSelected = selectedOptions.filter((t) => ids.includes(t[idKey]));
        const additionalSelected = options.filter(
          (o) => ids.includes(o[idKey]) && !stillSelected.some((t) => t[idKey] === o[idKey]),
        );
        const newSelectedOptions = [...stillSelected, ...additionalSelected];
        setIsDirty?.(true);
        setSelectedOptions(newSelectedOptions);
        onChange(newSelectedOptions);
      }}
      value={selectedOptions.map((t) => t[idKey] as string)}
      data={[
        ...selectedOptions.map((t) => ({ label: getItemLabel(t), value: t[idKey] as string })),
        ...options
          .filter((t) => !excludeIds?.includes(t[idKey]) && !selectedOptions.some((st) => st[idKey] === t[idKey]))
          .map((t) => ({
            label: getItemLabel(t),
            value: t[idKey] as string,
          })),
        ...(loading ? [{ value: 'loading', label: 'Loading...', disabled: true }] : []),
      ]}
      filter={(_, selected) => !selected}
      withinPortal
      {...multiSelectProps}
    />
  );
};
