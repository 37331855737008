import { Badge } from '@mantine/core';

import { StatusKey, statusToColorMap } from 'constants/badgeMappingStatus';

type Props = {
  status: StatusKey;
  daysRemaining?: number;
};

export const CellStatusBadge = ({ status, daysRemaining = 0 }: Props) => {
  const { label, color } = statusToColorMap[status];
  const finalColor = daysRemaining < 0 ? statusToColorMap.Late.color : color;
  return (
    <Badge tt="none" key={label} color={finalColor} c="white" variant="filled">
      {label}
    </Badge>
  );
};
