import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';

import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import type { WorkOrderStatusTypeName } from 'constants/badgeMappingStatus';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedGet, useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { useSelectedProjectFacility, type SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import useSetupModule from 'hooks/useSetupModule';
import { getBillOfProcessColDef } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/columnDefs';
import type { Task } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';
import { useUnitOfMeasure } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';
import { bomItemColDef } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/columnDefs';
import type { BOMItem } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/types';

import { useCanEditWorkOrder } from '../../WorkOrdersPage/common';
import type { WorkOrder } from '../../WorkOrdersPage/types';
import { useWorkOrders } from '../../WorkOrdersPage/useWorkOrders';
import { getWorkOrderRequestItemColumnDef } from './columnDefs';
import { DeleteBOPModal } from './DeleteBOPModal';
import type { DetailTab } from './SecondaryPane/WorkRequestOrderDetailsPane';
import type { WorkOrderItem } from './types';
import { WorkRequestOrderItemsContext } from './WorkRequestOrderItemsContext';

/**
 * The Props used by any Pane of the WorkOrder Details page
 * via useWorkOrderItems() or useWorkRequestOrderItems()
 */
export type WorkOrderPageProps = {
  selectedItem: SelectedItem;
  selectedWorkOrderItems: WorkOrderItem[];
  setSelectedWorkOrderItems: (items: WorkOrderItem[]) => void;
  workOrder: WorkOrder;
  workOrderUpdated: (updatedWO: WorkOrder) => void;
  serverSideGridProps: ReturnType<typeof useWorkOrderItems>;
};

const woMayBeInProgressStatuses: WorkOrderStatusTypeName[] = ['Blocked', 'Not Started', 'In Progress'] as const;

const useWorkOrderItems = (workOrder: WorkOrder) => {
  const { fetchPage, setDefaultOpts } = useWrappedPaginatedGet<WorkOrderItem>('shop/workOrderItem', {
    lazy: true,
  });
  const { fetchPage: fetchPageBOM } = useWrappedPaginatedGet<BOMItem>('shop/workOrderItem/:id/bom', { lazy: true });
  const { fetchPage: fetchPageBOP } = useWrappedPaginatedGet<Task>('shop/task', {
    lazy: true,
    defaultConfig: {
      params: {
        orderBy: 'createdOn:asc',
      },
    },
  });

  useEffect(() => {
    setDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          workOrderId: workOrder.workOrderId,
          orderBy: 'workOrderItemName:asc',
        },
      },
    });
  }, [setDefaultOpts, workOrder.workOrderId]);
  const isInProgress = useMemo(
    () => woMayBeInProgressStatuses.includes(workOrder.workOrderStatusTypeName),
    [workOrder.workOrderStatusTypeName],
  );
  const { editTasks: canEditTasks, editItems: canEditItems } = useCanEditWorkOrder(workOrder);
  const colDef = useMemo(
    () =>
      getWorkOrderRequestItemColumnDef<WorkOrderItem>({
        nameKey: 'workOrderItemName',
        isInProgress,
        canEditItems,
        canEditTasks,
        isCompleted: workOrder.workOrderStatusTypeName === 'Completed',
      }),
    [isInProgress, canEditItems, canEditTasks, workOrder.workOrderStatusTypeName],
  );
  const bopColDef = useMemo(() => getBillOfProcessColDef(), []);
  return useServerSideGrid({
    tableName: 'word-order-items',
    fetchPage,
    colDef,
    saveFilters: false,
    // Using billOfProcessId instead of workOrderItemId
    // so that we can refresh the detail row when we update the bill of process
    rowId: 'billOfProcessId',
    detailTables: [
      {
        title: 'Bill of Materials',
        icon: 'BillOfMaterials',
        colDef: bomItemColDef,
        fetchPage: fetchPageBOM,
        configMapper: (parentRow) => ({
          url: `shop/workOrderItem/${parentRow.workOrderItemId}/bom`,
        }),
      },
      {
        title: 'Bill of Process',
        icon: 'WorkCellTask',
        colDef: bopColDef,
        fetchPage: fetchPageBOP,
        configMapper: (parentRow) => ({
          params: {
            workRequestItemId: parentRow.workRequestItemId,
          },
        }),
        rightSide: ({ billOfProcessId, workOrderItemTypeId }, refresh) => (
          <DeleteBOPModal billOfProcessId={billOfProcessId} itemTypeId={workOrderItemTypeId} refresh={refresh} />
        ),
      },
    ],
  });
};

/**
 * The `useWorkOrderItems` call is used here,
 * so that the AG Grid helpers (like `refreshDetailGrid`)
 * can be called by both panes.
 * This allows the right (editing) pane to refresh the left (table) pane
 * whenever data is updated.
 */
const WorkOrderItemsWrapper = ({
  children,
  ...props
}: { children: ReactNode } & Omit<
  WorkOrderPageProps,
  'serverSideGridProps' | 'selectedWorkOrderItems' | 'setSelectedWorkOrderItems'
>) => {
  const serverSideGridProps = useWorkOrderItems(props.workOrder);
  const [selectedWorkOrderItems, setSelectedWorkOrderItems] = useState<WorkOrderItem[]>([]);
  const [selectedTab, setSelectedTab] = useState<DetailTab>('detail');
  return (
    <WorkRequestOrderItemsContext.Provider
      value={useMemo(
        () => ({
          serverSideGridProps,
          selectedWorkOrderItems,
          setSelectedWorkOrderItems,
          selectedTab,
          setSelectedTab,
          ...props,
        }),
        [serverSideGridProps, selectedWorkOrderItems, selectedTab, props],
      )}
    >
      {children}
    </WorkRequestOrderItemsContext.Provider>
  );
};

/**
 * Used to verify the selected `WorkOrder` both exists and is loaded into memory.
 */
export const WorkOrderSelectedWrapper = ({
  children,
  selectedItem,
}: {
  selectedItem: SelectedItem;
  children: ReactNode;
}) => {
  const { facilities, loading: loadingFacilities } = useFacilities();
  const { selectFacility } = useSelectedProjectFacility();
  const navigate = useEvolveNavigate('', true);
  const { currentModule } = useSetupModule();
  const module = useMemo(() => (currentModule() ?? 'shop').toLocaleLowerCase(), [currentModule]);

  const { id: workOrderId } = useParams();
  const { apiCall: getWorkOrder, loading } = useWrappedGet<WorkOrder>(`shop/workOrder/${workOrderId}`, {
    lazy: true,
  });
  const { uoms } = useUnitOfMeasure();
  const { selectedWorkOrder, setSelectedWorkOrder } = useWorkOrders();
  const workOrderMatches = useMemo(
    () => selectedWorkOrder?.workOrderId === workOrderId,
    [selectedWorkOrder?.workOrderId, workOrderId],
  );
  useEffect(() => {
    if (loadingFacilities) return;
    if (!workOrderMatches) {
      getWorkOrder()
        .then((wo) => {
          if (selectedItem.id !== wo.workRequest.facilityId) {
            const facility = facilities.find((f) => f.facilityId === wo.workRequest.facilityId);
            if (isNotNil(facility)) {
              notifications.show({
                title: 'Work Order in a different facility',
                message: `Swapped to facility ${facility.facilityName}`,
              });
              selectFacility(facility);
            }
          }
          setSelectedWorkOrder(wo);
        })
        .catch(() => {
          navigate(`/${module}/work-orders`);
        });
    }
  }, [
    facilities,
    getWorkOrder,
    loadingFacilities,
    module,
    navigate,
    selectFacility,
    selectedItem.id,
    selectedItem.type,
    setSelectedWorkOrder,
    workOrderMatches,
  ]);

  if (isNil(selectedWorkOrder) || isNil(uoms) || loading || !workOrderMatches) {
    return <Loader m="lg" />;
  }
  if (
    selectedItem.id !== selectedWorkOrder.workRequest.facilityId &&
    selectedItem.id !== selectedWorkOrder.workRequest.projectId
  ) {
    setTimeout(() => navigate(`/${module}/work-orders`));
    return null;
  }
  return (
    <WorkOrderItemsWrapper
      selectedItem={selectedItem}
      workOrder={selectedWorkOrder}
      workOrderUpdated={setSelectedWorkOrder}
    >
      {children}
    </WorkOrderItemsWrapper>
  );
};
