import { ReactComponent as AccountCircle } from '../images/icons/account-circle.svg';
import { ReactComponent as Add } from '../images/icons/add.svg';
import { ReactComponent as Apps } from '../images/icons/apps.svg';
import { ReactComponent as Assembly } from '../images/icons/assembly.svg';
import { ReactComponent as Attachment } from '../images/icons/attachment.svg';
import { ReactComponent as CalendarBoard } from '../images/icons/calendar-board.svg';
import { ReactComponent as CheckCircle } from '../images/icons/check-circle-icon.svg';
import { ReactComponent as ClearFilters } from '../images/icons/clear-filters.svg';
import { ReactComponent as Close } from '../images/icons/close-icon.svg';
import { ReactComponent as Copy } from '../images/icons/content-copy.svg';
import { ReactComponent as Delete } from '../images/icons/delete.svg';
import { ReactComponent as DeveloperBoard } from '../images/icons/developer-board.svg';
import { ReactComponent as Download } from '../images/icons/download-2.svg';
import { ReactComponent as DrawingDocument } from '../images/icons/drawing-document.svg';
import { ReactComponent as DrawingTypeCategory } from '../images/icons/drawing-type-category.svg';
import { ReactComponent as Export } from '../images/icons/export.svg';
import { ReactComponent as Folder } from '../images/icons/folder.svg';
import { ReactComponent as ExternalURL } from '../images/icons/globe.svg';
import { ReactComponent as Help } from '../images/icons/help.svg';
import { ReactComponent as Home } from '../images/icons/home.svg';
import { ReactComponent as Info } from '../images/icons/info_outline.svg';
import { ReactComponent as Insights } from '../images/icons/insights.svg';
import { ReactComponent as Kanban } from '../images/icons/kanban.svg';
import { ReactComponent as ArrowDown } from '../images/icons/keyboard-arrow-down.svg';
import { ReactComponent as ArrowLeft } from '../images/icons/keyboard-arrow-left.svg';
import { ReactComponent as ArrowRight } from '../images/icons/keyboard-arrow-right1.svg';
import { ReactComponent as Link } from '../images/icons/link.svg';
import { ReactComponent as MoreOptsVert } from '../images/icons/more-vert-alt.svg';
import { ReactComponent as Part } from '../images/icons/part.svg';
import { ReactComponent as Refresh } from '../images/icons/refresh.svg';
import { ReactComponent as Repair } from '../images/icons/repair.svg';
import { ReactComponent as Search } from '../images/icons/search-alt.svg';
import { ReactComponent as Spools } from '../images/icons/spools.svg';
import { ReactComponent as TableChart } from '../images/icons/table-chart.svg';
import { ReactComponent as TrendingDown } from '../images/icons/trending-down.svg';
import { ReactComponent as TrendingUp } from '../images/icons/trending-up.svg';
import { ReactComponent as Upload } from '../images/icons/upload.svg';
import { ReactComponent as WorkCell } from '../images/icons/work-cell.svg';
import { ReactComponent as WriteInItem } from '../images/icons/write-in-item.svg';
import { ReactComponent as Company } from '../images/nav-icons/admin-icons/company-properties.svg';
import { ReactComponent as Settings } from '../images/nav-icons/admin-icons/setting.svg';
import { ReactComponent as Projects } from '../images/nav-icons/common-icons/projects.svg';
import { ReactComponent as Shops } from '../images/nav-icons/common-icons/shops.svg';
import { ReactComponent as ProjectMaterials } from '../images/nav-icons/design-icons/project-materials.svg';
import { ReactComponent as WorkRequest } from '../images/nav-icons/field-icons/work-requests.svg';
import { ReactComponent as BillOfMaterials } from '../images/nav-icons/material-icons/bill-of-materials.svg';
import { ReactComponent as Catalog } from '../images/nav-icons/material-icons/catalog-setup.svg';
import { ReactComponent as DataImport } from '../images/nav-icons/shop-icons/data-import.svg';
import { ReactComponent as Fabrication } from '../images/nav-icons/shop-icons/fabrication.svg';
import { ReactComponent as Machine } from '../images/nav-icons/shop-icons/machine.svg';
import { ReactComponent as ShopConfig } from '../images/nav-icons/shop-icons/shop-configuration.svg';
import { ReactComponent as ShopMembers } from '../images/nav-icons/shop-icons/shop-members.svg';
import { ReactComponent as ShopProperties } from '../images/nav-icons/shop-icons/shop-properties.svg';
import { ReactComponent as ShopSetup } from '../images/nav-icons/shop-icons/shop-setup.svg';
import { ReactComponent as WorkCellTask } from '../images/nav-icons/shop-icons/shop-tasks-nav.svg';
import { ReactComponent as WorkCellQueue } from '../images/nav-icons/shop-icons/shop-work-cell-queue.svg';
import { ReactComponent as WorkCellsAndMachines } from '../images/nav-icons/shop-icons/work-cells-and-machines.svg';
import { ReactComponent as WorkOrder } from '../images/nav-icons/shop-icons/work-orders.svg';
import { ReactComponent as WorkPlanning } from '../images/nav-icons/shop-icons/work-planning.svg';

/**
 * The map defining all possible custom Evolve icons
 */
export const iconMap = {
  AccountCircle,
  Add,
  Apps,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Assembly,
  Attachment,
  BillOfMaterials,
  CalendarBoard,
  Catalog,
  CheckCircle,
  ClearFilters,
  Close,
  Company,
  Copy,
  DataImport,
  Delete,
  DeveloperBoard,
  Download,
  DrawingDocument,
  DrawingTypeCategory,
  Export,
  ExternalURL,
  Fabrication,
  Folder,
  Help,
  Home,
  Info,
  Kanban,
  Link,
  Machine,
  MoreOptsVert,
  Part,
  Insights,
  Refresh,
  ProjectMaterials,
  Projects,
  Repair,
  Search,
  Settings,
  ShopConfig,
  ShopMembers,
  ShopProperties,
  Shops,
  ShopSetup,
  Spools,
  TableChart,
  TrendingDown,
  TrendingUp,
  Upload,
  WorkCell,
  WorkCellQueue,
  WorkCellTask,
  WorkCellsAndMachines,
  WorkOrder,
  WorkPlanning,
  WorkRequest,
  WriteInItem,
} as const;
