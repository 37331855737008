import { Button, Text, Title } from '@mantine/core';

import useDeviceSize from 'hooks/useDeviceSize';

import MobileErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-mobile.png';
import TabletErrorBackground from '../assets/images/backgrounds/ErrorPage-bg-tablet.png';
import DesktopErrorBackground from '../assets/images/backgrounds/ErrorPage-bg.png';
import { FullPageError } from './FullPageError';

export const ErrorPage = () => {
  const { deviceSize } = useDeviceSize();
  // eslint-disable-next-line no-restricted-globals
  const refreshPage = () => location.reload();
  const contactSupport = () => (window.location.href = 'https://evolvemep.com/contact-support');

  return (
    <FullPageError
      backgroundSrc={
        // eslint-disable-next-line no-nested-ternary
        deviceSize === 'DESKTOP'
          ? DesktopErrorBackground
          : deviceSize === 'TABLET'
          ? TabletErrorBackground
          : MobileErrorBackground
      }
    >
      <Title fz={100}>Uh oh</Title>
      <Text ta="center" style={{ width: deviceSize === 'DESKTOP' ? '590px' : 3 / 5 }}>
        Information on this error has been captured and sent to Evolve so that it can be fixed.
      </Text>
      <Text ta="center" style={{ width: deviceSize === 'DESKTOP' ? '590px' : 3 / 5 }}>
        Refreshing the page typically resolves the issue. If the issue persists, please{' '}
        <Text span onClick={contactSupport} style={{ color: '#589CE4', cursor: 'pointer' }}>
          contact support.
        </Text>
      </Text>
      <Button color="dark" variant="filled" onClick={refreshPage}>
        Refresh page
      </Button>
    </FullPageError>
  );
};
