import { useState } from 'react';

import { Button, Divider, Flex, Modal, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { WrappedSelect } from 'components/Mantine/TypeSafeSelect';
import { INCLUDE_DELETED_OPTIONS } from 'constants/globalConstants';
import { useGetAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import { useFacilities } from 'hooks/projectsAndFacilities/useFacilities';
import { ProjectCreate, useProjects } from 'hooks/projectsAndFacilities/useProjects';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import type { CompanyId, User, UserId } from 'types/types-api';

export const CreateProjectModal = ({ companyId }: { companyId: CompanyId }) => {
  const { selectProject } = useSelectedProjectFacility();
  const [saving, setSaving] = useState(false);
  const { facilities } = useFacilities();
  const { projects, mutationRunning, createProject } = useProjects();
  const [open, setOpen] = useState(false);
  const { fetchPage: fetchUsers } = useWrappedPaginatedGet<User>('admin/user', { lazy: true });
  const [users, loadingUsers] = useGetAllDataFromFetcher(fetchUsers, {
    params: { companyId, includeDeleted: INCLUDE_DELETED_OPTIONS.NO },
  });

  const verifyUniqueProjectIdentifier = (projectIdentifier: string) =>
    projects.some((p) => p.projectIdentifier === projectIdentifier);

  const form = useForm<ProjectCreate>({
    initialValues: {
      projectName: '',
      projectIdentifier: '',
      departmentIds: [],
      userId: '' as UserId,
      companyId,
      isAdmin: true,
    },
    validate: {
      projectName: (v) => !v,
      projectIdentifier: (v) => !v || verifyUniqueProjectIdentifier(v),
      userId: (v) => !v,
    },
  });

  const onSubmit = (values: typeof form.values) => {
    setSaving(true);
    createProject(values)
      .then(selectProject)
      .then(() => setOpen(false))
      .finally(() => setSaving(false));
  };

  return (
    <>
      <Button
        color="dark"
        onClick={() => {
          form.reset();
          const shops = facilities
            .flatMap((f) => f.departments)
            .filter(isNotNil)
            .filter((d) => d.departmentType.departmentTypeName === 'Shop');
          if (shops.length === 1) {
            form.setFieldValue('departmentIds', [shops[0].departmentId]);
          }
          setOpen(true);
        }}
        leftIcon={<EvolveIcon icon="Add" size="sm" color="inherit" />}
      >
        Create Project
      </Button>
      <Modal
        centered
        title="Create new project"
        opened={open}
        onClose={() => setOpen(false)}
        closeOnClickOutside={false}
      >
        <form onSubmit={form.onSubmit(onSubmit)}>
          <Flex direction="column" gap="xs">
            <TextInput
              label="Project Title"
              maxLength={64}
              disabled={saving}
              {...form.getInputProps('projectName')}
              required
            />
            <TextInput
              label="Project Id"
              maxLength={64}
              description="Must be unique across all projects"
              disabled={saving}
              required
              {...form.getInputProps('projectIdentifier')}
              error={
                verifyUniqueProjectIdentifier(form.values.projectIdentifier) && !saving
                  ? 'Project Id already used.'
                  : form.getInputProps('projectIdentifier').error
              }
            />
            <Select
              label="Project Admin"
              data={users.map((u) => ({
                label: u.userEmail,
                value: u.userId,
              }))}
              searchable
              clearable
              required
              withinPortal
              disabled={saving}
              nothingFound={loadingUsers ? 'Loading...' : 'No users found.'}
              {...form.getInputProps('userId')}
            />
            <WrappedSelect
              label="Shop Department"
              data={facilities
                .map((f) => {
                  const shopDepartment = f.departments?.find((d) => d.departmentType.departmentTypeName === 'Shop');
                  if (isNil(shopDepartment)) return null;
                  return {
                    label: `${f.facilityName} / ${shopDepartment.departmentName}`,
                    value: shopDepartment.departmentId,
                  };
                })
                .filter(isNotNil)}
              searchable
              clearable
              withinPortal
              disabled={saving}
              {...form.getInputProps('departmentIds')}
              value={form.values.departmentIds?.[0]}
              onChange={(v) => form.setFieldValue('departmentIds', v ? [v] : [])}
            />
            <Divider my="xs" />
            <Flex justify="flex-end" gap="xs">
              <Button
                disabled={mutationRunning || saving}
                color="gray"
                c="dark"
                variant="subtle"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
              <Button type="submit" loading={saving} disabled={!form.isValid()}>
                Create
              </Button>
            </Flex>
          </Flex>
        </form>
      </Modal>
    </>
  );
};
