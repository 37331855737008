import { ColorSwatch, Tooltip } from '@mantine/core';

import { useMantineColor } from 'assets/icons/EvolveIcon';
import { statusToColorMap } from 'constants/badgeMappingStatus';
import type { WorkRequestItemStatusTypeId } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import { workOrderStatusMap, type WorkOrderItemStatusTypeId } from './types';

export const StatusPoint = ({ status }: { status: WorkRequestItemStatusTypeId | WorkOrderItemStatusTypeId }) => {
  const statusName = workOrderStatusMap[status];
  const { color, label } = statusToColorMap[statusName];
  const { mantineColor } = useMantineColor(color);
  return (
    <Tooltip withinPortal withArrow label={label}>
      <ColorSwatch size={8} color={mantineColor ?? color} withShadow={false} />
    </Tooltip>
  );
};
