import React from 'react';

import { KanbanCard } from '@evolvemep/foresite-components-ui-react';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { EvolveLink } from 'components/Mantine/Navigation/EvolveLink';
import { useEvolveNavigate } from 'components/Mantine/Navigation/useEvolveNavigate';
import { BADGE_MAPPING_STATUS } from 'constants/badgeMappingStatus';
import { getDaysBetween } from 'helpers/dateFunctions';

dayjs.extend(utc);

const linkStyles = {
  '& .link': {
    textDecoration: 'none',
    color: 'surface.lightSurface.primary',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

const WorkCellItem = ({
  status = '',
  color,
  disabled = false,
  workOrderName,
  projectName,
  qtyComments = null,
  date = '',
  taskName,
  workRequestName,
  workOrderId,
  taskId,
  sx,
}) => {
  const navigate = useEvolveNavigate('Work Cells');
  const convertedNeedByDate = dayjs(`${date}Z`);
  const needBy = convertedNeedByDate.isValid() ? convertedNeedByDate.local().format('MM/DD/YYYY') : '';
  const daysRemaining = getDaysBetween(convertedNeedByDate.toDate(), new Date());
  const expired = daysRemaining < 0;
  const newStatus = BADGE_MAPPING_STATUS[status];

  const onTitleClick = () => {
    navigate(`/shop/work-orders/${workOrderId}`);
  };

  return (
    <KanbanCard color={color} disabled={disabled} sx={{ display: 'flex', justifyContent: 'space-between', ...sx }}>
      <KanbanCard.Header>{projectName}</KanbanCard.Header>
      <KanbanCard.Content>
        <KanbanCard.Title color={color} onTitleClick={onTitleClick}>
          {workOrderName}
        </KanbanCard.Title>
        <Stack my="5px">
          <KanbanCard.Subtitle sx={{ lineHeight: 'initial' }}>{workRequestName}</KanbanCard.Subtitle>
        </Stack>
        <TaskNameSection disabled={disabled} taskName={taskName} taskId={taskId} />
        <KanbanCard.NeedBy expired={expired}>{needBy}</KanbanCard.NeedBy>
        <KanbanCard.Remaining expired={expired}>{daysRemaining}</KanbanCard.Remaining>
      </KanbanCard.Content>
      <KanbanCard.Footer
        comments={qtyComments}
        label={newStatus?.label}
        status={expired ? 'pastDaysRemaining' : newStatus?.variant}
      />
    </KanbanCard>
  );
};

export default WorkCellItem;

const TaskNameSection = ({ disabled, taskName, taskId }) => {
  if (disabled) return <KanbanCard.MainContent>{taskName}</KanbanCard.MainContent>;
  return (
    <Box sx={linkStyles}>
      <EvolveLink to={`/shop/task-viewer/${taskId}`} from="Work Cells">
        <KanbanCard.MainContent>{taskName}</KanbanCard.MainContent>
      </EvolveLink>
    </Box>
  );
};
