// Copyright ©️ 2024 eVolve MEP, LLC

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { notifications } from '@mantine/notifications';

import { useUser } from 'app/UserContext';
import { getAllDataFromFetcher } from 'helpers/getAllDataFromFetcher';
import { isNotNil } from 'helpers/isNotNil';
import useGeneralContext from 'helpers/useGeneralContext';
import {
  useWrappedDelete,
  useWrappedGet,
  useWrappedPaginatedGet,
  useWrappedPatch,
  useWrappedPost,
} from 'hooks-api/useWrappedApiCall';
import { Address, CompanyId, UserId } from 'types/types-api';

import { DepartmentTypeId, type Department, type DepartmentId } from './types';
import type { FacilityId } from './useFacilities';
import { useSelectedProjectFacility } from './useSelectedProjectFacility';

declare const projectIdSymbol: unique symbol;
export type ProjectId = string & { [projectIdSymbol]: never };

declare const projectTypeIdSymbol: unique symbol;
export type ProjectTypeId = string & { [projectTypeIdSymbol]: never };

export type ProjectType = {
  projectTypeId: ProjectTypeId;
  projectTypeName: string;
};

export type ProjectUnitsType = 'Metric' | 'Imperial';

export type DepType = {
  departmentTypeId: DepartmentTypeId;
  departmentTypeName: 'Procurement' | 'Design' | 'Shop';
  sortOrder: number;
};

export type Project = {
  projectId: ProjectId;
  projectName: string;
  projectIdentifier: string;
  companyId: CompanyId;
  projectType: ProjectType | null;
  address: Address;
  units: ProjectUnitsType;
  departments:
    | (Department & {
        facility?: {
          facilityId: FacilityId;
          facilityName: string;
        };
      })[]
    | null;
};

export type ProjectUpdateDepReturn = {
  departmentId: DepartmentId;
  departmentTypeId: DepartmentTypeId;
  departmentName: string;
  facility: {
    facilityId: FacilityId;
    facilityName: string;
  };
};

export type ProjectUpdateReturn = Omit<Project, 'departments'> & {
  departments: ProjectUpdateDepReturn[];
};

type ProjectsContextType = {
  refetch: () => Promise<void>;
  projects: Project[];
  loading: boolean;
  mutationRunning: boolean;
  errors: any;
  createProject: (data: ProjectCreate) => Promise<Project>;
  updateProject: (projectId: ProjectId, data: ProjectUpdate) => Promise<ProjectUpdateReturn>;
  deleteProject: (projectId: ProjectId) => Promise<void>;
  projTypes: ProjectType[] | null;
  depTypes: DepType[] | null;
};

const ProjectsContext = React.createContext<ProjectsContextType | undefined>(undefined);

export type ProjectCreate = {
  projectName: string;
  projectIdentifier: string;
  departmentIds?: DepartmentId[];
  companyId: CompanyId;
  userId: UserId;
  isAdmin: true;
};

export type ProjectUpdate = {
  projectName?: string;
  units?: ProjectUnitsType;
  projectIdentifier?: string;
  projectTypeId?: string;
  departmentIds?: DepartmentId[];
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

export const ProjectsProvider = ({ children }: { children: ReactNode }) => {
  const { selectedItem, clearSelectedItem } = useSelectedProjectFacility();
  const { user } = useUser();
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [projTypes, setProjTypes] = useState<ProjectType[] | null>(null);
  const [depTypes, setDepTypes] = useState<DepType[] | null>(null);
  const { fetchPage, ...rest } = useWrappedPaginatedGet<Project>('admin/project', { lazy: true });

  useEffect(() => {
    if (
      isNotNil(projects) &&
      selectedItem?.type === 'PROJECT' &&
      !projects.some((p) => p.projectId === selectedItem.id)
    ) {
      clearSelectedItem();
    }
  }, [clearSelectedItem, projects, selectedItem?.id, selectedItem?.type]);

  const refetch = useCallback(async () => {
    if (isNotNil(user?.companyId)) {
      await getAllDataFromFetcher(fetchPage, {
        params: {
          companyId: user.companyId,
        },
      }).then(setProjects);
    }
  }, [fetchPage, user?.companyId]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { apiCall: createCall, loading: loadingCreate } = useWrappedPost<Project, ProjectCreate>('admin/project');
  const { apiCall: updateCall, loading: loadingUpdate } = useWrappedPatch<ProjectUpdateReturn, ProjectUpdate>(
    'admin/project',
  );
  const { apiCall: deleteCall, loading: loadingDelete } = useWrappedDelete<Project>('admin/project');

  const { apiCall: getProjectTypes, loading: loadingProjTypes } = useWrappedGet<ProjectType[]>('admin/projecttype', {
    lazy: true,
  });
  const { apiCall: getDepartmentTypes, loading: loadingDepTypes } = useWrappedGet<DepType[]>('admin/departmenttype', {
    lazy: true,
  });

  useEffect(() => {
    if (isNotNil(user)) {
      getProjectTypes({ params: { companyId: user.companyId } }).then(setProjTypes);
    }
    getDepartmentTypes().then(setDepTypes);
  }, [user, getProjectTypes, getDepartmentTypes]);

  const updateProject = useCallback<ProjectsContextType['updateProject']>(
    (projectId, data) =>
      updateCall(data, { url: `admin/project/${projectId}` }).then(async (res) => {
        notifications.show({
          title: 'Successfully updated',
          message: `Project ${res.projectName} was successfully updated.`,
          color: 'green',
        });
        await refetch();
        return res;
      }),
    [updateCall, refetch],
  );

  const createProject = useCallback<ProjectsContextType['createProject']>(
    async (data) => {
      const res = await createCall(data);
      await refetch();
      return res;
    },
    [createCall, refetch],
  );

  const deleteProject = useCallback<ProjectsContextType['deleteProject']>(
    async (projectId) => {
      await deleteCall({
        url: `admin/project/${projectId}`,
      });
      await refetch();
    },
    [deleteCall, refetch],
  );

  const value = useMemo(
    () => ({
      refetch,
      projects: projects ?? [],
      loading: rest.loading || loadingProjTypes || loadingDepTypes,
      mutationRunning: loadingCreate || loadingUpdate || loadingDelete || rest.loading,
      errors: rest.errors,
      createProject,
      updateProject,
      deleteProject,
      projTypes,
      depTypes,
    }),
    [
      refetch,
      projects,
      rest.loading,
      rest.errors,
      loadingProjTypes,
      loadingDepTypes,
      loadingCreate,
      loadingUpdate,
      loadingDelete,
      createProject,
      updateProject,
      deleteProject,
      projTypes,
      depTypes,
    ],
  );
  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};

export const useProjects = () => useGeneralContext(ProjectsContext, 'Projects');
