import { useState } from 'react';

import { Button, Divider, Flex, Modal, PasswordInput, Space } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import { NewPasswordInput } from './NewPasswordInput';

type Props = {
  opened: boolean;
  onClose: () => void;
  onComplete?: () => void;
};

type ChangePasswordBody = {
  currentPassword: string;
  newPassword: string;
};

export const ResetPasswordModal = ({ opened, onClose: closeModal, onComplete }: Props) => {
  const { apiCall, loading } = useWrappedPost<unknown, ChangePasswordBody>('admin/authentication/changePassword');
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const form = useForm<ChangePasswordBody>({
    initialValues: {
      currentPassword: '',
      newPassword: '',
    },
    validate: {
      currentPassword: (p) => !p,
      newPassword: (p) => !p,
    },
  });

  const onClose = () => {
    form.reset();
    closeModal();
  };

  const onSubmit = (values: typeof form.values) => {
    apiCall(values).then(() => {
      notifications.show({
        title: 'Successfully changed',
        message: 'Password successfully updated.',
        color: 'green',
      });
      onComplete?.();
      onClose();
    });
  };

  const submitDisabled = !form.isDirty() || !form.isValid() || !passwordIsValid;

  return (
    <Modal
      opened={opened}
      closeOnClickOutside={!form.isDirty()}
      closeOnEscape={false}
      onClose={onClose}
      title="Reset password"
      centered
    >
      <form onSubmit={submitDisabled ? () => form.validate() : form.onSubmit(onSubmit)}>
        <Flex direction="column" gap="xs">
          <PasswordInput
            label="Current password"
            {...form.getInputProps('currentPassword')}
            autoFocus
            disabled={loading}
          />
          <Space />
          <NewPasswordInput
            {...form.getInputProps('newPassword')}
            loading={loading}
            setPasswordIsValid={setPasswordIsValid}
          />
          <Divider />
          <Flex justify="flex-end" gap="md">
            <Button variant="outline" color="gray" c="dark" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
            <Button type="submit" loading={loading} disabled={submitDisabled}>
              Save changes
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};
