import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

import { PageNotFound } from 'app/PageNotFound';

import {
  EvolveMembersInterfaceNavContent,
  CustomersInterfaceNavContent,
  CompanyEntitlementInterfaceNavContent,
} from './ManagementNavInterfaces';

const ManagementRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate to="evolve-customers" />} />
    <Route path="/evolve-members" element={<EvolveMembersInterfaceNavContent />} />
    <Route path="/evolve-customers" element={<CustomersInterfaceNavContent />} />
    <Route path="/evolve-customers/:companyId" element={<CompanyEntitlementInterfaceNavContent />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default ManagementRoutes;
