import { useEffect, useState } from 'react';

import { Flex, Paper, useMantineTheme } from '@mantine/core';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { AgGridStyleTooltip } from 'components/Mantine/AgGridStyleTooltip';
import { SearchableCheckList } from 'components/Mantine/SearchableCheckList';
import { TextWithRef } from 'components/Mantine/TextWithRef';
import { isNotNil } from 'helpers/isNotNil';
import { useWrappedPaginatedGet } from 'hooks-api/useWrappedApiCall';
import type { SelectedItem } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import type { TaskType } from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/types';

import type { BillOfProcessId } from '../../types';
import type { WorkRequestOrderProps } from '../../WorkRequestOrderItemsContext';
import { useDocumentsCache } from '../WorkRequestOrderDetail/Attachments/useDocumentsCache';
import { AddTaskPopover } from './AddTaskPopover';
import { DocumentImage } from './useDocumentImage';

const SelectableTask = ({ task }: { task: TaskType }) => {
  const theme = useMantineTheme();
  return (
    <Paper withBorder p={0} style={{ width: 400, overflow: 'hidden', borderColor: theme.colors.violet[6] }}>
      <Flex>
        <Flex bg="white" align="center" justify="center" style={{ width: 48, height: 48, minWidth: 48 }}>
          <DocumentImage
            documentId={task.taskTypeImageId}
            alt={task.taskTypeName}
            fallback={<EvolveIcon icon="WorkCellTask" color="violet.8" />}
          />
        </Flex>
        <Flex pl="sm" bg="violet.1" direction="column" justify="space-evenly" style={{ width: '100%' }}>
          <AgGridStyleTooltip label={task.taskTypeName} position="top-start">
            <TextWithRef fz="sm" c="violet.8">
              {task.taskTypeName}
            </TextWithRef>
          </AgGridStyleTooltip>
          {isNotNil(task.taskTypeDescription) && (
            <AgGridStyleTooltip label={task.taskTypeDescription} position="top-start">
              <TextWithRef fz="sm" c="violet.9">
                {task.taskTypeDescription}
              </TextWithRef>
            </AgGridStyleTooltip>
          )}
        </Flex>
      </Flex>
    </Paper>
  );
};

type Props = {
  selectedItem: SelectedItem;
  refresh: (billOfProcessId: BillOfProcessId) => void;
} & WorkRequestOrderProps;

export const TasksView = ({ selectedItem, refresh, ...props }: Props) => {
  const [selectedTasks, setSelectedTasks] = useState<TaskType[]>([]);
  const { requestDocumentDetails } = useDocumentsCache();
  const { data, ...paginatedGet } = useWrappedPaginatedGet<TaskType>('shop/taskType', {
    lazy: true,
    defaultConfig: {
      params: {
        orderBy: 'taskTypeName',
        companyId: selectedItem.companyId,
      },
    },
  });
  useEffect(() => {
    requestDocumentDetails(data.map((d) => d.taskTypeImageId).filter(isNotNil));
  }, [data, requestDocumentDetails]);

  return (
    <SearchableCheckList
      paginatedGet={{ data, ...paginatedGet }}
      idKey="taskTypeId"
      onChange={setSelectedTasks}
      value={selectedTasks}
      rightSideComponent={
        <AddTaskPopover
          taskTypes={selectedTasks}
          onUpdateBillOfProcess={(billOfProcessId) => {
            refresh(billOfProcessId);
            setSelectedTasks([]);
          }}
          {...('workOrder' in props
            ? { workOrderId: props.workOrder.workOrderId }
            : { workRequestId: props.workRequest.workRequestId })}
        />
      }
      textInputProps={{ placeholder: 'Search tasks...', style: { width: '90%', maxWidth: 400 } }}
      flexListProps={{ pb: 'sm', style: { flex: '1 0 0' } }}
      getItemLabel={(task) => <SelectableTask task={task} />}
    />
  );
};
