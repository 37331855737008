import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { TabBar } from 'components';
import { Breadcrumbs } from 'components/NavigationComponents';
import StackRow from 'components/StackRow';
import AdminTab from 'modules/Admin/CompanyProperties/AdminTab/AdminTab';

import LicensePoolViewer from './CompanyEntitlementViewer/LicensePoolViewer';

const CompanyEntitlementPool = () => {
  const { state } = useLocation();
  const { linkState: companyName } = state || { linkState: '' };

  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const breadcrumbs = [
    {
      label: 'Evolve Customers',
      url: '/management/evolve-customers',
    },
    {
      label: `${companyName}`,
    },
  ];

  const TabContents = ({ componentToRender, index }) => (
    <Box role="tabpanel" hidden={activeTab !== index} sx={{ height: 1 / 1 }}>
      {activeTab === index && componentToRender}
    </Box>
  );

  return (
    <>
      <Box sx={{ padding: '0 8px', height: 'inherit' }}>
        <HeaderWrapper>
          <StackRow>
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              sx={{ fontSize: '16px' }}
              separator={<EvolveIcon icon="ArrowRight" size="xs" />}
            />
            <StyledTabs value={activeTab} onChange={handleTabChange}>
              <StyledTab label="LICENSE POOL" />
              <StyledTab label="ADMINS" />
            </StyledTabs>
          </StackRow>
        </HeaderWrapper>
        <TabContents index={0} componentToRender={<LicensePoolViewer />} />
        <TabContents index={1} componentToRender={<AdminTab />} />
      </Box>
    </>
  );
};

export default CompanyEntitlementPool;

const HeaderWrapper = ({ children }) => (
  <Stack
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{
      padding: '8px 0',
    }}
  >
    {children}
  </Stack>
);

const StyledTabs = (props) => (
  <TabBar
    sx={{
      '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      },
      '& .MuiTabs-indicatorSpan': {
        height: 2,
        width: '100%',
        backgroundColor: 'secondary.main',
      },
    }}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    {...props}
  />
);

const StyledTab = (props) => (
  <Tab
    disableRipple
    sx={{
      width: 164,
      fontWeight: 'bold',
      letterSpacing: 0.5,
      color: 'surface.lightSurface.secondary',
      '&.MuiTab-root': {
        padding: 0,
      },
      '&.Mui-selected': {
        color: 'secondary.main',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }}
    {...props}
  />
);
