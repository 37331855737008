import { Anchor, Flex, Text, AnchorProps } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { isNotNil } from 'helpers/isNotNil';
import useSetupModule from 'hooks/useSetupModule';

import type { LocationState, Page, PageTitle } from './types';
import { useEvolveLocation } from './useEvolveLocation';

const Breadcrumb = ({
  title,
  index,
  url,
}: {
  title: string;
} & (
  | {
      index: number;
      url?: never;
    }
  | {
      url: `/${string}` | '..';
      index?: never;
    }
)) => {
  const navigate = useNavigate();
  const { currentModule } = useSetupModule();
  return (
    <>
      <Anchor
        c="dimmed"
        onClick={() =>
          isNotNil(url)
            ? navigate(url === '..' ? url : `/${currentModule().toLocaleLowerCase()}${url}`)
            : navigate(-(index ?? 1))
        }
        underline={false}
      >
        {title}
      </Anchor>
      <Text>/</Text>
    </>
  );
};

const headerProps: AnchorProps = {
  size: 26,
  fw: 600,
  mr: 'md',
} as const;

export type PageBreadcrumbProps = {
  /** If `true`, will ignore history when rendering breadcrumbs */
  noHistory?: boolean;
} & (
  | {
      /** Title of the page we are on */
      title: PageTitle;
      /** The parent page to fallback to if we are unable to pull it from react-router-dom */
      parentPage?: Page;
    }
  | {
      // If parentPage is provided, title can be a raw string.
      title: PageTitle | string;
      parentPage: Page;
    }
);

export const getHistory = (locationState: LocationState) => {
  if (!locationState?.from) return [];
  if (Array.isArray(locationState?.from)) {
    return locationState?.from?.sort((a, b) => a.index - b.index);
  }
  const locationString: string = locationState?.from;
  return [
    {
      title: locationString,
      url: `shop/${locationString.toLowerCase().replace(' ', '-')}`,
      index: 0,
    },
  ];
};

export const PageBreadcrumb = ({ title, parentPage, noHistory = false }: PageBreadcrumbProps) => {
  const { state: locationState } = useEvolveLocation();
  const history = getHistory(locationState);
  const hasLocationHistory = !noHistory && history.length > 0;
  const hasParentPage = hasLocationHistory || isNotNil(parentPage);
  return (
    <Flex align="center" gap="xs">
      {hasLocationHistory &&
        history.map(({ title: breadcrumbTitle }, i) => (
          <Breadcrumb key={breadcrumbTitle} title={breadcrumbTitle} index={history.length - i} />
        ))}
      {!hasLocationHistory && isNotNil(parentPage) && <Breadcrumb {...parentPage} />}
      <Anchor c="dark" style={{ cursor: 'initial' }} underline={false} {...(!hasParentPage ? headerProps : {})}>
        {title}
      </Anchor>
    </Flex>
  );
};
