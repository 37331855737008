import { useCallback } from 'react';

import { Entitlement } from 'types/types-api';

import { useWrappedPatch } from './useWrappedApiCall';

type RequestBody = {
  checkedOut: boolean;
  endDate?: string; // DateOnly
};

export const useUpdateEntitlement = () => {
  const { apiCall, ...rest } = useWrappedPatch<Entitlement, RequestBody>('admin/entitlement');
  const doUpdateEntitlement = useCallback(
    async ({ entitlementId, body }: { entitlementId: string; body: RequestBody }) =>
      apiCall(body, {
        url: `admin/entitlement/${entitlementId}`,
      }),
    [apiCall],
  );
  return { doUpdateEntitlement, ...rest };
};
