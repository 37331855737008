import { useState } from 'react';

import { Button, Flex, Modal, Radio, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useUser } from 'app/UserContext';
import { isNil } from 'helpers/isNotNil';
import {
  NewUnitOfMeasureBody,
  UnitOfMeasure,
  useUnitOfMeasure,
} from 'modules/Field/WorkRequests/WorkRequest/WorkRequestPage/useUnitOfMeasure';

type Props = {
  defaultValue?: string;
  opened: boolean;
  onClose: () => void;
  onAdded: (newUOM: UnitOfMeasure) => void;
};

const NewUnitOfMeasureForm = ({ defaultValue, onClose, onAdded }: Props) => {
  const { user } = useUser();
  const { uoms, uomTypes, addNewUOM } = useUnitOfMeasure();
  const [saving, setSaving] = useState(false);
  const form = useForm<NewUnitOfMeasureBody>({
    initialValues: {
      unitOfMeasureName: defaultValue,
      unitOfMeasureCode: '',
      companyId: user?.companyId,
      unitOfMeasureTypeId: '',
    } as NewUnitOfMeasureBody,
    validate: {
      unitOfMeasureName: (n) => isNil(n) || n.length === 0,
      unitOfMeasureCode: (c) => isNil(c) || c.length === 0,
      unitOfMeasureTypeId: isNil,
    },
  });

  const onSubmit = (values: NewUnitOfMeasureBody) => {
    setSaving(true);
    addNewUOM(values)
      .then(onAdded)
      .then(onClose)
      .finally(() => setSaving(false));
  };
  const uomNameExists = uoms?.some((u) => form.values.unitOfMeasureName === u.unitOfMeasureName);
  const uomCodeExists = uoms?.some((u) => form.values.unitOfMeasureCode === u.unitOfMeasureCode);
  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Flex direction="column" gap="md">
        <Flex gap="md">
          <TextInput
            label="UOM Name"
            disabled={saving}
            style={{ width: '100%' }}
            maxLength={32}
            required
            {...form.getInputProps('unitOfMeasureName')}
            error={uomNameExists}
          />
          <TextInput
            label="Code"
            autoFocus
            disabled={saving}
            style={{ width: 110 }}
            maxLength={32}
            required
            {...form.getInputProps('unitOfMeasureCode')}
            error={uomCodeExists}
          />
        </Flex>
        {uomNameExists && (
          <Text fz="sm" c="red">
            UOM <b>{form.values.unitOfMeasureName}</b> already exists.
          </Text>
        )}
        {uomCodeExists && (
          <Text fz="sm" c="red">
            UOM Code <b>{form.values.unitOfMeasureCode}</b> already exists.
          </Text>
        )}
        <Radio.Group {...form.getInputProps('unitOfMeasureTypeId')}>
          <Flex gap="lg">
            {uomTypes?.map((t) => (
              <Radio
                key={t.unitOfMeasureTypeId}
                disabled={saving}
                value={t.unitOfMeasureTypeId}
                label={t.unitOfMeasureTypeName}
              />
            ))}
          </Flex>
        </Radio.Group>
        <Flex gap="md" justify="flex-end">
          <Button color="gray" c="dark" variant="subtle" onClick={onClose} disabled={saving}>
            Cancel
          </Button>
          <Button type="submit" loading={saving} disabled={!form.isValid() || uomNameExists || uomCodeExists}>
            Add
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export const NewUnitOfMeasureModal = ({ ...props }: Props) => (
  <Modal
    title="New UOM"
    opened={props.opened}
    onClose={props.onClose}
    centered
    closeOnClickOutside={false}
    closeButtonProps={{ tabIndex: -1 }}
    // Popovers have a z-index of 300,
    // so we want to be a little higher than that for this particular modal
    zIndex={302}
    overlayProps={{ zIndex: 301 }}
  >
    <NewUnitOfMeasureForm {...props} />
  </Modal>
);
