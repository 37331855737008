// Copyright ©️ 2024 eVolve MEP, LLC

import type { ColDef } from 'ag-grid-community';

import { CellRenderer } from 'components/Mantine/CellRenderer';
import { lockedColDef } from 'helpers/ag-grid/baseColumnDef';
import { CustomSelectComponent } from 'helpers/ag-grid/CustomSelectComponent';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { IncludeDeletedOptions, type Entitlement, type User } from 'types/types-api';

import { CompanyMemberMenu, CompanyMembersBulkAction } from './CompanyMemberMenu';
import { DeleteEntitlement } from './DeleteEntitlement';

const fallback = '--';

export const getCompanyMembersColDef = ({
  hasDetailTable,
  actionSingleUser,
  filterOnStatus = true,
}: {
  hasDetailTable: boolean;
  filterOnStatus?: boolean;
  actionSingleUser?: (user: User, selectedAction: CompanyMembersBulkAction) => void;
}): ColDef<User>[] =>
  [
    {
      ...lockedColDef(),
      width: 56,
      checkboxSelection: ({ data }) => !data?.isDeleted,
    },
    {
      ...lockedColDef(),
      width: 48,
      cellRenderer: 'agGroupCellRenderer',
      hide: !hasDetailTable,
    },
    {
      field: 'userName',
      headerName: 'User',
      valueGetter: ({ data }) => {
        const nameParts = [data?.userFirstName, data?.userLastName].filter(isNotNil);
        if (nameParts.length > 0) {
          return nameParts.join(' ');
        }
        return fallback;
      },
      // filter: 'agTextColumnFilter',
      // filterParams: {
      //   filterOptions: ['contains'],
      //   maxNumConditions: 1,
      //   buttons: ['reset'],
      // },
    },
    {
      headerName: 'Email',
      field: 'userEmail',
      valueFormatter: ({ value }) => value ?? fallback,
      flex: 1,
      minWidth: 250,
      // filter: 'agTextColumnFilter',
      // filterParams: {
      //   filterOptions: ['contains'],
      //   maxNumConditions: 1,
      //   buttons: ['reset'],
      // },
    },
    {
      headerName: 'Office Phone',
      width: 200,
      field: 'workPhoneNumber',
      valueFormatter: ({ value }) => value ?? fallback,
      sortable: false,
    },
    {
      headerName: 'Mobile Phone',
      width: 200,
      field: 'personalPhoneNumber',
      valueFormatter: ({ value }) => value ?? fallback,
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'inviteStatus',
      minWidth: 150,
      cellStyle: ({ data }) => ({
        // eslint-disable-next-line no-nested-ternary
        color: data?.isDeleted ? 'red' : data?.inviteStatus === 'Active' ? 'teal' : 'orange',
      }),
      valueFormatter: ({ data, value }) => (data?.isDeleted ? 'Deleted' : value),
      filter: filterOnStatus
        ? CustomSelectComponent({
            label: 'User status',
            defaultValue: IncludeDeletedOptions.NO,
            data: [
              {
                label: 'Active users',
                value: IncludeDeletedOptions.NO,
              },
              {
                label: 'Deleted users',
                value: IncludeDeletedOptions.ONLY_DELETED,
              },
              {
                label: 'All users',
                value: IncludeDeletedOptions.YES,
              },
            ],
            filterKey: 'includeDeleted',
          })
        : undefined,
    },
    {
      ...lockedColDef('right'),
      width: 56,
      cellRenderer: CellRenderer<User>(
        ({ data }) =>
          isNotNil(actionSingleUser) ? <CompanyMemberMenu actionSingleUser={actionSingleUser} user={data} /> : null,
        { show: ({ data }) => !data.isDeleted },
      ),
      hide: isNil(actionSingleUser),
    },
  ] as const;

export const getEntitlementsColDef = (): ColDef<Entitlement>[] =>
  [
    {
      ...lockedColDef(),
      width: 60,
      cellRenderer: CellRenderer<Entitlement>(({ data, api }) => (
        <DeleteEntitlement entitlement={data} refresh={() => api.refreshServerSide({ purge: true })} />
      )),
    },
    {
      field: 'productPool.label',
      flex: 1,
    },
  ] as const;
