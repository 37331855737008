import { useState } from 'react';

import { Button, List, Text, Checkbox, Flex, MantineProvider, Loader } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { addDays, format } from 'date-fns';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { darkScrollBarStyle } from 'styles/theme/scrollBarStyle';
import type { Entitlement } from 'types/types-api';

import { useEntitlementCheckInOutForm } from './useEntitlementCheckInOutForm';

export const EntitlementCheckInOutForm = ({ goBack }: { goBack: () => void }) => {
  const [selected, setSelected] = useState<Entitlement[]>([]);
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 1));
  const { refreshEntitlements, updateEntitlement, loading, licenses } = useEntitlementCheckInOutForm();
  const [mutationRunning, setMutationRunning] = useState<'checking-in' | 'checking-out'>();

  const onCheckOut = () => {
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');
    setMutationRunning('checking-out');
    Promise.all(
      selected
        .filter((license) => !license.isCheckedOut)
        .map(({ entitlementId, isCheckedOut }) =>
          updateEntitlement({
            entitlementId,
            body: {
              checkedOut: !isCheckedOut,
              endDate: formattedEndDate,
            },
          }),
        ),
    )
      .then(refreshEntitlements)
      .then(() => {
        setSelected((s) => s.filter((l) => l.isCheckedOut));
      })
      .finally(() => setMutationRunning(undefined));
  };

  const onCheckIn = () => {
    setMutationRunning('checking-in');
    Promise.all(
      selected
        .filter((l) => l.isCheckedOut)
        .map(({ entitlementId, isCheckedOut }) =>
          updateEntitlement({
            entitlementId,
            body: {
              checkedOut: !isCheckedOut,
            },
          }),
        ),
    )
      .then(refreshEntitlements)
      .then(() => {
        setSelected((s) => s.filter((l) => !l.isCheckedOut));
      })
      .finally(() => setMutationRunning(undefined));
  };

  const checkedOutLicenses = licenses.filter((license) => license.isCheckedOut === true);

  return (
    <Flex direction="column" gap="md" style={{ width: 300 }}>
      <Flex>
        <Button onClick={goBack} leftIcon={<EvolveIcon icon="ArrowLeft" color="inherit" size="xs" />} variant="subtle">
          Back
        </Button>
      </Flex>
      <CheckedOutPrompt checkedOutLicenses={checkedOutLicenses} />
      <Flex gap="xs" justify="space-between">
        <Button
          fullWidth
          loading={mutationRunning === 'checking-out'}
          disabled={!selected.some((l) => !l.isCheckedOut)}
          color="gray.2"
          c="dark"
          onClick={onCheckOut}
          size="xs"
        >
          Check-out
        </Button>
        <Button
          fullWidth
          loading={mutationRunning === 'checking-in'}
          disabled={!selected.some((l) => l.isCheckedOut)}
          color="gray.2"
          c="dark"
          onClick={onCheckIn}
          size="xs"
        >
          Check-in
        </Button>
      </Flex>
      <DateInput
        icon={<EvolveIcon icon="CalendarBoard" color="inherit" />}
        label="End Date"
        value={endDate}
        onChange={(d) => setEndDate((ed) => d ?? ed)}
        disabled={!!mutationRunning}
      />
      <Flex
        p="xs"
        style={{
          maxHeight: 200,
          overflow: 'auto',
          ...darkScrollBarStyle,
        }}
        gap="sm"
        direction="column"
      >
        {loading && <Loader />}
        <MantineProvider theme={{ colorScheme: 'light' }} inherit>
          {licenses.map((entitlement) => (
            <Checkbox
              key={entitlement.entitlementId}
              disabled={!!mutationRunning}
              mr="xs"
              size="sm"
              styles={{
                label: {
                  color: '#bbb',
                },
              }}
              label={`${entitlement.productPool.label}${entitlement.isCheckedOut ? ' (Checked out)' : ''}`}
              checked={selected.some((p) => p.entitlementId === entitlement.entitlementId)}
              onChange={() => {
                const index = selected.findIndex((e) => e.entitlementId === entitlement.entitlementId);
                if (index < 0) {
                  setSelected((s) => [...s, entitlement]);
                } else {
                  setSelected((s) => [...s.slice(0, index), ...s.slice(index + 1)]);
                }
              }}
            />
          ))}
        </MantineProvider>
      </Flex>
    </Flex>
  );
};

const CheckedOutPrompt = ({ checkedOutLicenses }: { checkedOutLicenses: Entitlement[] }) => (
  <Flex direction="column">
    <Text fw={500}>{checkedOutLicenses.length > 0 ? 'Checked out on this device:' : 'No licenses checked out.'}</Text>
    {checkedOutLicenses.length > 0 && (
      <List pr="sm">
        {checkedOutLicenses.map((license) => (
          <List.Item key={license.entitlementId}>
            <Text ta="left" fz="sm">
              <strong>{license.productPool.label}</strong> (Exp.{' '}
              {new Date(license.checkedOutEndDate).toLocaleDateString()})
            </Text>
          </List.Item>
        ))}
      </List>
    )}
  </Flex>
);
