import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { PageBreadcrumb } from 'components/Mantine/Navigation/PageBreadcrumb';
import MiniSpinner from 'components/MiniSpinner';
import { isNotNil } from 'helpers/isNotNil';

import { useTaskViewerContext } from '../../TaskViewerContext';
import Status from './Status';

const getStatus = (curStatus) => {
  if (curStatus === 'Completed') return 'Completed';
  if (curStatus === 'Not Started') return 'Not Started';
  return 'In Progress';
};

const Header = () => {
  const { taskProperty, taskStatus, taskName, loadingTask, needBy } = useTaskViewerContext();
  const badgedLabel = getStatus(taskStatus);

  return (
    <Stack direction="row" sx={{ mt: 0.5 }}>
      <PageBreadcrumb
        title={taskName}
        noHistory
        parentPage={{
          title: 'Work Cell Queue',
          url: '/work-cell-queue',
        }}
      />
      {isNotNil(taskStatus) && <Status date={needBy} variant={badgedLabel} />}
      {loadingTask ? (
        <MiniSpinner sx={{ width: 50 }} size="16px" height="24px" />
      ) : (
        <Typography variant="subtitle1" style={{ marginLeft: 30 }}>
          {taskProperty?.workRequestItemName}
        </Typography>
      )}
    </Stack>
  );
};

export default Header;
