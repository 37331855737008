import { useState } from 'react';

import { ActionIcon, Anchor, Avatar, Button, Divider, Flex, MantineProvider, Popover, Space } from '@mantine/core';

import { useUser } from 'app/UserContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { isNotNil } from 'helpers/isNotNil';
import useBreakpoint from 'hooks/useBreakpoint';
import useSignOut from 'modules/Authentication/auth/useSignOut';

import { EntitlementCheckInOutForm } from './Desktop/LicenseCheckDropdownContent/EntitlementCheckInOutForm';
import EditProfileModal from './EditProfileModal/EditProfileModal';
import { ProfileInformation } from './ProfileInformation';
import { useProfilePictureContext } from './ProfilePictureContext';
import { ResetPasswordModal } from './ResetPasswordModal/ResetPasswordModal';

const modals = ['Edit Profile', 'Reset Password', 'License Check-in/Check-out'] as const;
type ModalType = typeof modals[number];

export const ProfileDropdown = () => {
  const { user } = useUser();
  const signOut = useSignOut();
  const [opened, setOpened] = useState(false);
  const { isMobile } = useBreakpoint();
  const [activeModalType, setActiveModalType] = useState<ModalType>();
  const { profilePicture } = useProfilePictureContext();

  const openModal = (modalType: ModalType) => () => {
    setActiveModalType(modalType);
    setOpened(modalType === 'License Check-in/Check-out');
  };

  return (
    <>
      <Popover opened={opened} onChange={setOpened} position="bottom-end">
        <Popover.Target>
          {isMobile ? (
            <ActionIcon onClick={() => setOpened((o) => !o)} variant="filled" size="lg" radius="xl">
              <Avatar src={profilePicture} color="gray.5" radius="100%" />
            </ActionIcon>
          ) : (
            <Button color="gray" radius="xl" size="sm" onClick={() => setOpened((o) => !o)} pl="xs" pr={0}>
              <EvolveIcon icon="ArrowDown" size="xs" color="gray.3" />
              <Space mx={4} />
              <Avatar src={profilePicture} color="gray.5" radius="100%" />
            </Button>
          )}
        </Popover.Target>
        <Popover.Dropdown mt="xs" p="md" style={{ maxWidth: 350 }}>
          {activeModalType === 'License Check-in/Check-out' ? (
            <EntitlementCheckInOutForm goBack={() => setActiveModalType(undefined)} />
          ) : (
            <Flex direction="column" align="center" gap="sm">
              <ProfileInformation />
              <Divider style={{ width: '100%' }} />
              {modals.map((modal) => (
                <Anchor key={modal} size="sm" onClick={openModal(modal)}>
                  {modal}
                </Anchor>
              ))}
              <Anchor size="sm" onClick={signOut}>
                Sign out
              </Anchor>
            </Flex>
          )}
        </Popover.Dropdown>
      </Popover>
      {isNotNil(user) && (
        <MantineProvider theme={{ colorScheme: 'light' }} inherit>
          <ResetPasswordModal
            opened={activeModalType === 'Reset Password'}
            onClose={() => setActiveModalType(undefined)}
          />
          <EditProfileModal
            user={user}
            opened={activeModalType === 'Edit Profile'}
            onClose={() => setActiveModalType(undefined)}
          />
        </MantineProvider>
      )}
    </>
  );
};
