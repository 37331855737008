import { useEffect, useCallback } from 'react';

import { useUser } from 'app/UserContext';
import { isNil, isNotNil } from 'helpers/isNotNil';
import { useEntitlementGet } from 'hooks-api/useEntitlementGet';
import { useUpdateEntitlement } from 'hooks-api/useUpdateEntitlement';

export const useEntitlementCheckInOutForm = () => {
  const { user } = useUser();
  // TODO: useEntitlementGet is paginated, but the usage isn't really implemented as such
  const { data: getData, loading: getLoading, entitlementGetList } = useEntitlementGet({}, true);
  const { loading: mutationLoading, doUpdateEntitlement } = useUpdateEntitlement();

  useEffect(() => {
    if (isNotNil(user?.userId)) {
      entitlementGetList({ userId: user.userId });
    }
  }, [entitlementGetList, user?.userId]);

  const refreshEntitlements = useCallback(() => {
    entitlementGetList({ userId: user?.userId });
  }, [user?.userId, entitlementGetList]);

  const licenses = getData?.data.filter((l) => isNil(l.productPool.desktopLicenseId)) ?? [];

  return {
    refreshEntitlements,
    updateEntitlement: doUpdateEntitlement,
    data: getData,
    loading: getLoading,
    licenses,
    mutationLoading,
  };
};
