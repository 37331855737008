import { useApolloClient } from '@apollo/client';
import { Auth } from 'aws-amplify';

import { useUser } from 'app/UserContext';
import { useSelectedProjectFacility } from 'hooks/projectsAndFacilities/useSelectedProjectFacility';
import useSetupModule from 'hooks/useSetupModule';

const useSignOut = () => {
  const client = useApolloClient();
  const { setUser } = useUser();
  const { clearSelectedItem } = useSelectedProjectFacility();
  const { resetModule } = useSetupModule();

  const signOut = async () => {
    client.stop();
    await client?.clearStore();
    await Auth.signOut();
    removeCognitoTokens();
    resetModule();
    clearSelectedItem();
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  return signOut;
};

export default useSignOut;

const removeCognitoTokens = () => {
  Object.entries(localStorage)
    .map((item) => item[0])
    .filter((item) => item.substring(0, 30) === 'CognitoIdentityServiceProvider')
    .map((item) => localStorage.removeItem(item));
};
