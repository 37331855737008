import { useFacilitiesProjects } from 'app/FacilitiesProjectsContext';
import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { RightSideFlyout } from 'components';
import useBreakpoint from 'hooks/useBreakpoint';

import { ShopTasksProvider } from '../ShopTasks/ShopTasksContext';
import { useDataContext } from './WorkCellsAndMachines/contexts/DataContext';
import { MachinesPopup, TaskTypesPopup } from './WorkCellsAndMachines/FlyoutPopus';
import { MachinesProvider } from './WorkCellsAndMachines/FlyoutPopus/MachinesContext';
import { getFacilityId } from './WorkCellsAndMachines/helpers/helpers';

const WorkCellsAndMachinesFlyoutMenu = () => {
  const { isTablet, isDesktop } = useBreakpoint();
  const { selectedItem } = useFacilitiesProjects();
  const facilityId = getFacilityId(selectedItem);
  const { closeFlyoutMenu, setCloseFlyoutMenu } = useDataContext();

  const showFlyoutItems = facilityId && (isTablet || isDesktop);

  const flyoutItems = {
    staticList: [
      ...(showFlyoutItems
        ? [
            {
              section: 'Shop Tasks',
              icon: <EvolveIcon icon="WorkCellTask" />,
              custom: true,
              children: ({ onPanelClose }) => (
                <ShopTasksProvider>
                  <TaskTypesPopup onClose={onPanelClose} />
                </ShopTasksProvider>
              ),
              testId: 'ShopTaskFlyoutMenuButton',
            },
            {
              section: 'Machines',
              icon: <EvolveIcon icon="Machine" />,
              custom: true,
              children: ({ onPanelClose }) => (
                <MachinesProvider>
                  <MachinesPopup onClose={onPanelClose} />
                </MachinesProvider>
              ),
              testId: 'MachineFlyoutMenuButton',
            },
          ]
        : []),
    ],
  };

  // eslint-disable-next-line no-console
  const onDock = () => console.log('docking');

  return (
    <RightSideFlyout
      flyoutItems={flyoutItems}
      onDock={onDock}
      closeFlyoutMenu={closeFlyoutMenu}
      setCloseFlyoutMenu={setCloseFlyoutMenu}
    />
  );
};

export default WorkCellsAndMachinesFlyoutMenu;
