import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useMantineNotifications } from 'components/Mantine/useMantineNotifications';
import { extractErrorMessage } from 'helpers/extractError';
import { isNotNil } from 'helpers/isNotNil';

import useEvolveApi, { AdditionalRequestConfig, EvolveURL } from './useEvolveApi';

type Opts<D> = {
  /**
   * The default config passed to each api call.
   * To make changes per-call, pass the new config params into the `apiCall`.
   */
  defaultConfig?: AdditionalRequestConfig<D>;
  /**
   * By default, any error on a PATCH, POST, or DELETE will show a notification.
   * If `true`, will not show this notification and instead will throw the raw error.
   */
  dontAlertOnError?: boolean;
};

// All other API calls happen as a result of an action.
// GET requests, specifically, *may* happen immediately upon page load,
// but may also either need to wait for something else, or be called again later.
export type GetOpts<D> = Opts<D> & {
  /** If `true`, will not make a request call upon hook instantiation */
  lazy?: boolean;
};

const useWrappedApiCallNoBody = <ResponseType, DataType = any>(
  method: 'get' | 'apiDelete',
  url: EvolveURL,
  defaultOpts?: GetOpts<DataType>,
) => {
  const { showError } = useMantineNotifications();
  const callNumRef = useRef(0);
  // Frequently, the passed in parameters are recalculated on a rerender
  // so we need to be very careful to not rerender/recalculate when they change.
  const [initialOpts, setDefaultOpts] = useState(defaultOpts);
  const [loading, setLoading] = useState(!initialOpts?.lazy && method === 'get');
  const [data, setData] = useState<ResponseType | undefined>(undefined);
  const [errors, setErrors] = useState<any>();
  const apiMethod = useEvolveApi()[method];

  const apiCall = useCallback(
    async (
      config?: AdditionalRequestConfig<DataType>,
      /** If `true`, will throw instead of returning if a more recent call has been made by the time this call finishes. */
      throwOnMoreRecent = false,
    ) => {
      if (method === 'get') callNumRef.current += 1;
      const callNum = callNumRef.current;
      setErrors(undefined);
      setLoading(true);
      return apiMethod<ResponseType>(config?.url ?? url, { ...initialOpts?.defaultConfig, ...config })
        .then((res) => {
          setData(res);
          return res;
        })
        .catch((err) => {
          const extractedError = extractErrorMessage(err);
          setErrors(extractedError);
          if (!initialOpts?.dontAlertOnError) {
            if (method === 'apiDelete') {
              showError(err);
            }
            throw extractedError;
          }
          throw err;
        })
        .then((res) => {
          if (throwOnMoreRecent && method === 'get' && callNum !== callNumRef.current) {
            const error = 'More recent call made.';
            throw error;
          }
          return res;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apiMethod, initialOpts?.defaultConfig, initialOpts?.dontAlertOnError, method, showError, url],
  );

  useEffect(() => {
    if (!initialOpts?.lazy && method !== 'apiDelete') {
      // For non-lazy GET requests, submit a request upon hook instantiation
      apiCall(initialOpts?.defaultConfig).catch(() => {});
    }
  }, [initialOpts, apiCall, method]);

  return {
    setDefaultOpts,
    apiCall,
    data,
    loading,
    errors,
  };
};

const useWrappedApiCallWithBody = <ResponseType, DataType>(
  method: 'post' | 'patch',
  url: EvolveURL,
  defaultOpts?: Opts<DataType>,
) => {
  const { showError } = useMantineNotifications();
  const [initialOpts, setDefaultOpts] = useState(defaultOpts);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ResponseType | undefined>(undefined);
  const [errors, setErrors] = useState<any>();
  const apiMethod = useEvolveApi()[method];

  const apiCall = useCallback(
    async (body: DataType, config?: AdditionalRequestConfig<DataType>) => {
      setErrors(undefined);
      setLoading(true);
      return apiMethod<ResponseType>(config?.url ?? url, body, { ...initialOpts?.defaultConfig, ...config })
        .then((res) => {
          setData(res);
          return res;
        })
        .catch((err) => {
          const extractedError = extractErrorMessage(err);
          setErrors(extractedError);
          if (!initialOpts?.dontAlertOnError) {
            showError(err);
            throw extractedError;
          }
          throw err;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [apiMethod, initialOpts?.defaultConfig, initialOpts?.dontAlertOnError, showError, url],
  );

  return { setDefaultOpts, apiCall, data, loading, errors };
};

/** Used for paginated responses */
export type EvolveApiReturn<DataType> = {
  data: DataType[];
  requestedSkip: number;
  requestedTake: number;
  entireCount: number;
};

export type PageFetcher<ResponseType, DataType = any> = (
  args: {
    skip: number;
    take?: number;
    searchPhrase?: string;
    orderBy?: string;
    /** If `true`, will throw instead of returning if a more recent call has been made by the time this call finishes. */
    throwOnMoreRecent?: boolean;
  },
  config?: AdditionalRequestConfig<DataType>,
) => Promise<EvolveApiReturn<ResponseType>>;

export type DefaultOptsSetter<DataType> = (
  newDefaultOpts: React.SetStateAction<
    | (GetOpts<DataType> & {
        lazy?: boolean | undefined;
      } & {
        perPage?: number | undefined;
      })
    | undefined
  >,
) => void;

export type WrappedPaginatedGet<ResponseType, DataType = any> = {
  setDefaultOpts: DefaultOptsSetter<DataType>;
  fetchPage: PageFetcher<ResponseType>;
  fetchNextPage: (config?: AdditionalRequestConfig<DataType>) => Promise<boolean>;
  /** Clears out all stored data (usually in prep for a new fetchNextPage) */
  reset: () => void;
  refetch: () => Promise<void>;
  data: ResponseType[];
  entireCount: number | undefined;
  loading: boolean;
  searchHandler: (searchPhrase: string) => void;
  sortHandler: (sort: string) => void;
  errors: any;
};

/**
 * Wrapper for Evolve's pagination API, used with `Multisearch` endpoints.
 */
export const useWrappedPaginatedGet = <ResponseType, DataType = any>(
  /** Can be overridden per-call via `config.url` */
  url: EvolveURL,
  /**
   * The default options to pass to the api calls.
   * Changing this input will not change the options - instead,
   * pass the new options into the `apiCall`.
   */
  defaultOpts?: GetOpts<DataType> & {
    /** How many results to return per call. */
    perPage?: number;
  },
): WrappedPaginatedGet<ResponseType, DataType> => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [initialOpts, setDefaultOptsInner] = useState(defaultOpts);
  const [lazyCallMade, setLazyCallMade] = useState(initialOpts?.lazy ?? true);
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: _unused1,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setDefaultOpts: _unused2,
    apiCall,
    ...rest
  } = useWrappedApiCallNoBody<EvolveApiReturn<ResponseType>, DataType>('get', url, {
    // We will handle a non-lazy call ourselves below in useEffect
    lazy: true,
  });
  const loadingRef = useRef(false);
  const hasMoreRef = useRef(true);
  const totalRequestedRef = useRef(0);
  // Used to make sure we don't override a newer call when an older call returns
  const numCallsRef = useRef(0);

  const setDefaultOpts = useCallback<DefaultOptsSetter<DataType>>((...opts) => {
    setDefaultOptsInner(...opts);
  }, []);
  useEffect(() => {
    setLazyCallMade(initialOpts?.lazy ?? false);
  }, [initialOpts]);
  // `data` above only has the most recent page,
  // so we keep track of all of the concatenated pages here
  const [fullData, setFullData] = useState<ResponseType[]>([]);
  const [entireCount, setEntireCount] = useState<number | undefined>();

  /**
   * Used to fetch an individual page.
   *
   * **Note:** Will not update the returned `data` from this hook.
   */
  const fetchPage = useCallback<PageFetcher<ResponseType, DataType>>(
    async (
      {
        skip,
        take = initialOpts?.perPage,
        searchPhrase: searchPhraseOverride = searchPhrase,
        orderBy: orderByOverride = orderBy,
        throwOnMoreRecent = false,
      },
      config,
    ) =>
      apiCall(
        {
          ...initialOpts?.defaultConfig,
          ...config,
          params: {
            ...initialOpts?.defaultConfig?.params,
            skip,
            take,
            ...(orderByOverride ? { orderBy: orderByOverride } : {}),
            ...(searchPhraseOverride ? { searchPhrase: searchPhraseOverride } : {}),
            ...config?.params,
          },
        },
        throwOnMoreRecent,
      ).then((res) => {
        setEntireCount(res.entireCount);
        return res;
      }),
    [apiCall, initialOpts?.defaultConfig, initialOpts?.perPage, orderBy, searchPhrase],
  );

  /**
   * Used to fetch the next page of data.
   * To access the data itself, use the `data` field returned from the hook.
   * @returns a boolean indicating whether or not there is still additional data
   */
  const fetchNextPage = useCallback(
    async (config?: AdditionalRequestConfig<DataType>) => {
      const numCall = numCallsRef.current;
      // If we're actively loading the next page,
      // or we've retrieved all available data,
      // don't bother sending another request.
      if (loadingRef.current || !hasMoreRef.current) {
        return hasMoreRef.current;
      }
      loadingRef.current = true;
      try {
        const res = await fetchPage({ skip: totalRequestedRef.current }, config);
        if (isNotNil(res) && numCall === numCallsRef.current) {
          setFullData((alreadyFetched) => [...alreadyFetched, ...res.data]);
          const total = Math.max(res.requestedTake, res.data.length) + res.requestedSkip;
          totalRequestedRef.current = total;
          const stillHasMore = total < res.entireCount;
          hasMoreRef.current = stillHasMore;
        }
        return hasMoreRef.current;
      } finally {
        if (numCall === numCallsRef.current) {
          loadingRef.current = false;
        }
      }
    },
    [fetchPage],
  );

  const reset = useCallback(() => {
    numCallsRef.current += 1;
    loadingRef.current = false;
    hasMoreRef.current = true;
    totalRequestedRef.current = 0;
    setFullData([]);
    setEntireCount(undefined);
  }, []);

  // Effect is used to make any non-lazy call
  // ie. an initial call, when search params change, etc
  useEffect(() => {
    // I'm not convinced this is the cleanest way to do this
    if (!initialOpts?.lazy && !lazyCallMade) {
      reset();
      setLazyCallMade(true);
      fetchNextPage().catch(() => {});
    }
  }, [lazyCallMade, fetchNextPage, initialOpts?.lazy, reset]);

  const refetch = useCallback(async () => {
    reset();
    await fetchNextPage();
  }, [reset, fetchNextPage]);

  const searchHandler = useCallback((value: string) => {
    setSearchPhrase(value.trim());
    setLazyCallMade(false);
  }, []);
  const sortHandler = useCallback((value: string) => {
    const valueWithoutSpaces = value.split(' ').join('');
    setOrderBy(valueWithoutSpaces.toLowerCase());
    setLazyCallMade(false);
  }, []);

  return {
    setDefaultOpts,
    fetchPage,
    fetchNextPage,
    reset,
    refetch,
    data: fullData,
    entireCount,
    searchHandler,
    sortHandler,
    ...rest,
  };
};

export const useWrappedGet = <ResponseType, DataType = any>(url: EvolveURL, opts?: GetOpts<DataType>) =>
  useWrappedApiCallNoBody<ResponseType, DataType>(
    'get',
    /** Can be overridden for each `apiCall`, if needed, via `config.url` */
    url,
    opts,
  );

export const useWrappedPost = <ResponseType, DataType>(
  /** Can be overridden for each `apiCall`, if needed, via `config.url` */
  url: EvolveURL,
  opts?: Opts<DataType>,
) => useWrappedApiCallWithBody<ResponseType, DataType>('post', url, opts);

export const useWrappedPatch = <ResponseType, DataType>(
  /** Can be overridden for each `apiCall`, if needed, via `config.url` */
  url: EvolveURL,
  opts?: Opts<DataType>,
) => useWrappedApiCallWithBody<ResponseType, DataType>('patch', url, opts);

export const useWrappedDelete = <ResponseType, DataType = any>(
  /** Can be overridden for each `apiCall`, if needed, via `config.url` */
  url: EvolveURL,
  opts?: Opts<DataType>,
) => useWrappedApiCallNoBody<ResponseType, DataType>('apiDelete', url, opts);
