import { AgGridReact } from 'ag-grid-react';

import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { CellStatusBadge } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestListElements/CellComponentStatus/CellStatusBadge';

import { getDaysRemainingWorkOrder } from '../../WorkOrdersPage/common';
import { WorkOrderActionButtons } from '../../WorkOrdersPage/WorkOrderActionButtons';
import { getPageTitle } from './common';
import { ItemsMultiSelectActions } from './ItemsMultiSelectActions';
import type { WorkOrderItem } from './types';
import { useWorkOrderItems } from './WorkRequestOrderItemsContext';

export const WorkOrderItemsTable = () => {
  const context = useWorkOrderItems();
  const { workOrder, workOrderUpdated, serverSideGridProps, setSelectedWorkOrderItems } = context;
  const { agGridProps, filterIsSet, refreshGrid } = serverSideGridProps;

  return (
    <>
      <BasePageHeader
        title={getPageTitle(workOrder)}
        parentPage={{
          title: 'Work Orders',
          url: '..',
        }}
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        topLeftComponent={
          <CellStatusBadge
            status={workOrder.workOrderStatusTypeName}
            daysRemaining={getDaysRemainingWorkOrder(workOrder)}
          />
        }
        topRightComponent={
          <WorkOrderActionButtons
            workOrder={workOrder}
            onUpdate={(updatedWorkOrder) => {
              workOrderUpdated(updatedWorkOrder);
              refreshGrid();
            }}
            inline={false}
          />
        }
        bottomLeftComponent={<ItemsMultiSelectActions {...context} />}
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<WorkOrderItem>
          {...agGridProps}
          noRowsOverlayComponent={() => (
            <NoRowsOverlay label="Add an item to begin." icon="WorkOrder" gridRef={agGridProps.ref} />
          )}
          rowSelection="multiple"
          onSelectionChanged={({ api }) => setSelectedWorkOrderItems(api.getSelectedRows())}
        />
      </div>
    </>
  );
};
