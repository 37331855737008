import { useEffect, useMemo, useRef } from 'react';

import { Button, Flex, Loader } from '@mantine/core';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-theme-quartz.css';
import 'ag-grid-enterprise';

import { baseColumnDef, baseGridDef } from 'helpers/ag-grid/baseColumnDef';

import { useSaveColumnState } from '../WorkOrders/WorkOrdersPage/useSaveColumnState';
import { getWorkOrderColumnDefs, getWorkRequestColumnDefs } from './columnDefs';
import { useInsights } from './useInsights';

export const InsightsDetailedReport = () => {
  const gridRef = useRef<AgGridReact>(null);
  const { saveColumnState, loadColumnState } = useSaveColumnState('Insights Chart', gridRef);

  const { wrDetailedData, woDetailedData, params, loading } = useInsights();
  const workOrderColumnDefs = useMemo(() => getWorkOrderColumnDefs(), []);
  const workRequestColumnDefs = useMemo(() => getWorkRequestColumnDefs(), []);

  useEffect(() => {
    if (loading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [loading]);

  const getColumnDef = useMemo(
    () =>
      params.selectedRecordType === 'Work Orders'
        ? { rowData: woDetailedData, columnDefs: workOrderColumnDefs }
        : { rowData: wrDetailedData, columnDefs: workRequestColumnDefs },
    [params.selectedRecordType, woDetailedData, workOrderColumnDefs, workRequestColumnDefs, wrDetailedData],
  );

  return (
    <Flex className="ag-theme-quartz" direction="column" style={{ height: '100%', margin: '0px 20px 20px 20px' }}>
      <Button
        onClick={() => {
          gridRef.current?.api.exportDataAsCsv({ fileName: params.selectedRecordType ?? '' });
        }}
        size="xs"
        sx={{ alignSelf: 'flex-end', margin: '10px 0px 10px 0px' }}
      >
        Export CSV
      </Button>
      <AgGridReact
        ref={gridRef}
        {...baseGridDef}
        defaultColDef={baseColumnDef}
        loadingOverlayComponent={() => <Loader size="lg" />}
        onGridReady={({ api }) => {
          if (loading) {
            api.showLoadingOverlay();
          }
          loadColumnState();
        }}
        {...getColumnDef}
        onFilterChanged={saveColumnState}
        onColumnMoved={saveColumnState}
        onColumnResized={saveColumnState}
        onColumnPinned={saveColumnState}
        onColumnVisible={saveColumnState}
        onSortChanged={saveColumnState}
      />
    </Flex>
  );
};
