import { useEffect, useMemo, useState, ComponentProps } from 'react';

import { Loader } from '@mantine/core';
import type { IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid

import type { SelectedFacility } from 'components/FacilityAndProjectWrapper/FacilityOrProjectRequired';
import { BasePageHeader } from 'components/Mantine/BasePageHeader';
import { ChangeTableViewProvider, useChangeTableView } from 'components/Mantine/ChangeTableView/useChangeTableView';
import type { ShowOpen } from 'components/Mantine/OpenClosedTab';
import type { WorkOrderStatusTypeName } from 'constants/badgeMappingStatus';
import 'helpers/ag-grid/ag-nested-grid-theme.css';
import { NoRowsOverlay } from 'helpers/ag-grid/NoRowsOverlay';
import { useServerSideGrid } from 'helpers/ag-grid/useServerSideGrid';
import { isNil, isNotNil } from 'helpers/isNotNil';
import {
  bomItemColDef,
  spoolsItemColDef,
} from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/columnDefs';
import { facilityProjectTypeToQueryParam } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/common';
import { useWorkRequests } from 'modules/Field/WorkRequests/WorkRequestsList/WorkRequestsPage/useWorkRequests';
import useDocumentTypes from 'modules/Shop/Fabrication/TaskViewer/PlansModelsContent/hooks/useDocumentTypes';

import { WorkRequestOrderDetailDrawer } from '../WorkOrder/WorkOrderItemsPage/SecondaryPane/WorkRequestOrderDetail/WorkRequestOrderDetailDrawer';
import WorkOrdersKanban from '../WorkOrdersKanban';
import WorkOrderCalendar from '../WorkOrdersList/WorkOrderCalendar';
import { getWorkOrderColDef } from './columnDefs';
import type { WorkOrder, WorkOrderStatusType } from './types';
import { useWorkOrders } from './useWorkOrders';

const statusesByGroup: Record<ShowOpen, WorkOrderStatusTypeName[]> = {
  open: ['Draft', 'Not Started', 'In Progress', 'Blocked'],
  closed: ['Completed'],
};

type Props = {
  workOrderStatuses: WorkOrderStatusType[];
  selectedFacility: SelectedFacility;
  commonTypeId: string;
};

const WorkOrdersTable = ({ workOrderStatuses, selectedFacility, commonTypeId }: Props) => {
  const { selectedWorkOrder, setSelectedWorkOrder, fetchWorkOrders, fetchBomItems, setWorkOrdersDefaultOpts } =
    useWorkOrders();
  const { fetchSpools } = useWorkRequests();
  const [selectedNode, setSelectedNode] = useState<IRowNode<WorkOrder> | undefined>();
  const [showOpen, setShowOpen] = useState<ShowOpen>('open');
  const possibleStatuses = useMemo(
    () => workOrderStatuses.filter((s) => statusesByGroup[showOpen].includes(s.workOrderStatusTypeName)),
    [showOpen, workOrderStatuses],
  );
  const workOrderColDef = useMemo(
    () => getWorkOrderColDef(possibleStatuses, setSelectedWorkOrder, showOpen === 'open'),
    [possibleStatuses, setSelectedWorkOrder, showOpen],
  );

  useEffect(() => {
    setSelectedWorkOrder(selectedNode?.data);
  }, [selectedNode?.data, setSelectedWorkOrder]);

  useEffect(() => {
    // The default `workOrderStatusIds` change when a user toggles the Open/Closed tabs,
    // so we use this effect to change the behavior of fetchPage.
    // Once fetchPage updates, the datasource will be recreated as part of a later useEffect
    // which will cause the entire Grid to reload.
    setWorkOrdersDefaultOpts({
      lazy: true,
      defaultConfig: {
        params: {
          [facilityProjectTypeToQueryParam[selectedFacility.type]]: selectedFacility.id,
          // Order by needBy if not ordering by anything else
          orderBy: 'needBy:asc',
          documentTypeIds: commonTypeId,
          workOrderStatusIds: possibleStatuses.map((s) => s.workOrderStatusTypeId).join(','),
        },
      },
    });
  }, [commonTypeId, possibleStatuses, selectedFacility.id, selectedFacility.type, setWorkOrdersDefaultOpts]);

  const { agGridProps, selectNextRow, filterIsSet } = useServerSideGrid({
    tableName: 'work-orders',
    colDef: workOrderColDef,
    fetchPage: fetchWorkOrders,
    rowId: 'workOrderId',
    detailTables: [
      {
        title: 'Bill of Materials',
        icon: 'BillOfMaterials',
        colDef: bomItemColDef,
        fetchPage: fetchBomItems,
        exportable: true,
        exportFileName: ({ workOrderName, workRequest: { projectName } }) => `${projectName}_${workOrderName}_BOM`,
        configMapper: ({ workOrderId }) => ({ params: { workOrderId } }),
      },
      {
        icon: 'Spools',
        title: 'Spools',
        colDef: spoolsItemColDef,
        fetchPage: fetchSpools,
        exportable: true,
        exportFileName: ({ workOrderName, workRequest: { projectName } }) => `${projectName}_${workOrderName}_Spools`,
        // retrieving spools linked to the id of the parent work request
        configMapper: ({ workRequest: { workRequestId } }) => ({ params: { workRequestId } }),
        hideHeader: true,
      },
    ],
  });

  return (
    <>
      <BasePageHeader
        title="Work Orders"
        showOpen={showOpen}
        setShowOpen={setShowOpen}
        gridRef={agGridProps.ref}
        filterIsSet={filterIsSet}
        viewsToShow={['list', 'kanban', 'calendar']}
      />
      <div className="ag-theme-quartz" style={{ height: '100%' }}>
        <AgGridReact<WorkOrder>
          {...agGridProps}
          rowSelection="single"
          onSelectionChanged={(e) => setSelectedNode(e.api.getSelectedNodes()[0])}
          noRowsOverlayComponent={() => (
            <NoRowsOverlay
              label={`This facility has no ${showOpen} work orders.`}
              icon="WorkOrder"
              gridRef={agGridProps.ref}
            />
          )}
        />
      </div>
      <WorkRequestOrderDetailDrawer
        workOrder={selectedWorkOrder}
        workOrderUpdated={(workOrder) => {
          setSelectedWorkOrder(workOrder);
          selectedNode?.updateData(workOrder);
        }}
        selectedItem={selectedFacility}
        onClose={() => {
          agGridProps.ref.current?.api?.deselectAll();
          setSelectedWorkOrder(undefined);
        }}
        navigate={(next = true) => {
          const index = selectedNode?.rowIndex;
          if (isNotNil(index)) {
            selectNextRow(index, next);
          }
        }}
      />
    </>
  );
};

const WorkOrdersPageWrapped = ({ selectedFacility }: { selectedFacility: SelectedFacility }) => {
  const { workOrderStatuses } = useWorkOrders();
  const { selectedView } = useChangeTableView();
  const { commonTypeId } = useDocumentTypes();
  if (isNil(workOrderStatuses) || isNil(commonTypeId)) {
    return <Loader m="sm" />;
  }

  if (selectedView === 'list') {
    return (
      <WorkOrdersTable
        workOrderStatuses={workOrderStatuses}
        selectedFacility={selectedFacility}
        commonTypeId={commonTypeId}
      />
    );
  }
  // TODO: Break some of this out once the other two views are written
  return (
    <>
      <BasePageHeader title="Work Orders" viewsToShow={['list', 'kanban', 'calendar']} />
      {selectedView === 'kanban' && <WorkOrdersKanban />}
      {selectedView === 'calendar' && <WorkOrderCalendar />}
    </>
  );
};

export const WorkOrdersPage = (props: ComponentProps<typeof WorkOrdersPageWrapped>) => (
  <ChangeTableViewProvider name="Work Orders">
    <WorkOrdersPageWrapped {...props} />
  </ChangeTableViewProvider>
);
