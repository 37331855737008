import { useState } from 'react';

import { Button, Flex, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { Link } from 'react-router-dom';

import { EvolveIcon } from 'assets/icons/EvolveIcon';
import { useWrappedPost } from 'hooks-api/useWrappedApiCall';

import { AuthFormWrapper } from '../components/AuthFormWrapper';
import type { LoginErrorCode } from '../components/LoginErrorAlert';

export const CheckEmailForPasswordReset = ({ email }: { email: string }) => {
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<LoginErrorCode>();
  const { apiCall: sendPasswordResetEmail } = useWrappedPost('admin/authentication/forgotPassword');

  const resendEmailLink = async () => {
    setErrorCode(undefined);
    try {
      setLoading(true);
      await sendPasswordResetEmail({}, { params: { email } });
      notifications.show({
        title: 'Email resent',
        message: 'Password reset email resent!',
        color: 'green',
      });
    } catch ({ code }: any) {
      setErrorCode(code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthFormWrapper
      title="Check your email!"
      onSubmit={resendEmailLink}
      errorCode={errorCode}
      onCloseError={() => setErrorCode(undefined)}
    >
      <Text>A reset password link has been sent to {email}</Text>
      <Button type="submit" loading={loading} fullWidth color="indigo.9">
        Resend link
      </Button>
      <Link className="link" data-cy="forgot-password" to="/auth/login">
        <Flex justify="center" align="center" gap={4}>
          <EvolveIcon icon="ArrowLeft" width={10} />
          <Text c="dimmed" fz="sm">
            Return to login
          </Text>
        </Flex>
      </Link>
      <Text align="center" variant="body1">
        Questions or problems?{' '}
        <Link className="link" to="https://www.evolvemep.com/contact-support/" target="_blank">
          Get in touch.
        </Link>
      </Text>
    </AuthFormWrapper>
  );
};
